import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useCart } from '../contexts/CartContext';
import productsData from '../components/ProductsData';
import '../customizeKitPage.css';
import FirstNav2 from '../components/FirstNav';
import GatewayPopup from '../components/GatewayPopup';

const CustomizeKitPage = () => {
  const { id } = useParams();
  const { addToCart, increaseQuantity, decreaseQuantity, getCartItem, cartItems } = useCart();
  const productKit = productsData.find(product => product.id === parseInt(id));
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(0);
  const [productsPerPage, setProductsPerPage] = useState(12);

  const [showPopup, setShowPopup] = useState(false);
  const [popupContent, setPopupContent] = useState({});

  

  useEffect(() => {
    const updateProductsPerPage = () => {
      const width = window.innerWidth;
      if (width >= 1200) {
        setProductsPerPage(12);
      } else if (width >= 768) {
        setProductsPerPage(8);
      } else {
        setProductsPerPage(4);
      }
    };

    window.addEventListener('resize', updateProductsPerPage);
    updateProductsPerPage();

    return () => window.removeEventListener('resize', updateProductsPerPage);
  }, []);

  const handleCloseItemPopup = () => setShowPopup(false);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
        handleCloseItemPopup();
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    // Cleanup event listener on component unmount
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  if (!productKit || !productKit.items) {
    return <div>Loading...</div>; // or handle error conditionally
  }


  const handleAddToKit = (product) => {
    const existingItem = getCartItem(product.id);
    if (existingItem) {
      increaseQuantity(product.id);
    } else {
      addToCart(product);
    }
  };

  const handleIncreaseQuantity = (item) => {
    increaseQuantity(item.id);
  };

  const handleDecreaseQuantity = (item) => {
    if (item.quantity > 1) {
      decreaseQuantity(item.id);
    }
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleShowDescription = (product) => {
    setPopupContent(product);
    setShowPopup(true);
  };

//   const filteredProducts = productKit.items.filter(product =>
//     product.name.toLowerCase().includes(searchTerm.toLowerCase())
//   );
const filteredProducts = productKit.items.filter(product =>
    product.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const startIndex = currentPage * productsPerPage;
  const endIndex = startIndex + productsPerPage;
  const currentProducts = filteredProducts.slice(startIndex, endIndex);

  const handleNext = () => {
    if (endIndex < filteredProducts.length) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrev = () => {
    if (startIndex > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

 

  const packageData = productKit.items;

  const selectedItems = cartItems;

  const totalPrice = selectedItems.reduce((total, item) => total + item.amount * item.quantity, 0) + productKit.amount;

  return (
    <div>
      <FirstNav2 />
      <div className="customize-kit-container">
        <h2>Customize Your Kit</h2>
        <div className="product-kit">
          <div className="customize-product-card">
            <div className="img">
              <img src={`${process.env.PUBLIC_URL}/${packageData.images[0]}`} alt={productKit.name} />
            </div>
            <div className="details">
              <h2 className="product-name">{productKit.name}</h2>
              <div className="price">
                <p>${productKit.amount}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="search-bar">
          <input
            type="text"
            placeholder="Search for items to add to Kit..."
            value={searchTerm}
            onChange={handleSearch}
          />
        </div>
        <div className="product-grid">
          {currentProducts.map((product) => {
            const cartItem = getCartItem(product.id);
            return (
              <div className="product-card" key={product.id}>
                <div className="img" onClick={() => handleShowDescription(product)}>
                  <img src={`${process.env.PUBLIC_URL}/${product.image}`} alt={product.name} />
                </div>
                <div className="details">
                  <h2 className="product-name">{product.name}</h2>
                  <div className="price">
                    <p>${product.amount}</p>
                  </div>
                  <div className="buy">
                    <button onClick={() => handleAddToKit(product)}>Add to Kit</button>
                    {cartItem && (
                      <div className="quantity-controls">
                        <button className="quantity-button" onClick={() => handleDecreaseQuantity(cartItem)}>-</button>
                        <span className="quantity">{cartItem.quantity}</span>
                        <button className="quantity-button" onClick={() => handleIncreaseQuantity(cartItem)}>+</button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <div className="navigation-buttons">
          <button onClick={handlePrev} disabled={startIndex === 0}>Prev</button>
          <button onClick={handleNext} disabled={endIndex >= filteredProducts.length}>Next</button>
        </div>
        <div className="selected-items">
          <h3>Selected Items</h3>
          <ul>
            {selectedItems.map(item => (
              <li key={item.id} className="selected-item">
                <span>{item.name}</span>
                <div className="quantity-controls">
                  <button className="quantity-button" onClick={() => handleDecreaseQuantity(item)}>-</button>
                  <span className="quantity">{item.quantity}</span>
                  <button className="quantity-button" onClick={() => handleIncreaseQuantity(item)}>+</button>
                </div>
              </li>
            ))}
          </ul>
        </div>
        <div className="total-price">
          <h4>Total Price: ${totalPrice.toFixed(2)}</h4>
        </div>
        <GatewayPopup
          show={showPopup}
          handleClose={() => setShowPopup(false)}
          content={
            <div>
              <div className="popup-header">
                <img src={`${process.env.PUBLIC_URL}/${popupContent.image}`} alt={popupContent.name} style={{ maxWidth: '100%', height: 'auto' }} />
                <h2>{popupContent.name}</h2>
              </div>
              <p>
                <span className='featureHead'>Description:</span>
                <br />
                {popupContent.description}
              </p>
              {popupContent.features && (
                <div>
                  <h6 className='featureHead'>Features</h6>
                  <ul>
                    {popupContent.features.map((feature, index) => (
                      <li key={index}>{feature}</li>
                    ))}
                  </ul>
                </div>
              )}
              {popupContent.benefits && (
                <div>
                  <h6 className='featureHead'>Benefits</h6>
                  <ul>
                    {popupContent.benefits.map((benefit, index) => (
                      <li key={index}>{benefit}</li>
                    ))}
                  </ul>
                </div>
              )}
              {popupContent.applications && (
                <div>
                  <h6 className='featureHead'>Applications</h6>
                  <ul>
                    {popupContent.applications.map((application, index) => (
                      <li key={index}>{application}</li>
                    ))}
                  </ul>
                </div>
              )}
              <div className="price">
                <p><strong>Price:</strong> ${popupContent.amount}</p>
              </div>
            </div>
          }
        />
      </div>
    </div>
  );
};

export default CustomizeKitPage;
