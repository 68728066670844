import React, { useState, useEffect } from 'react';
import axiosInstance from '../components/axiosInstance';
import ChatBox from './AdminChatBox1';
import { useNavigate, Link } from 'react-router-dom';
import axios from 'axios';
import '../AdminDashboard1.css';
import GatewayPopup from '../components/GatewayPopup';

function AdminDashboard1() {
  const [chats, setChats] = useState([]);
  const [inProgress, setInProgress] = useState([]);
  const [currentChat, setCurrentChat] = useState(null);
  const [userDetails, setUserDetails] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  
  const navigate = useNavigate();

  useEffect(() => {
    // Check if the user is authenticated
    const token = sessionStorage.getItem('token');
    if (!token) {
      navigate('/admin-login1');
      return;
    }

    const fetchAwaitingChats = async () => {
        try {
            const response = await axiosInstance.get('get_awaiting_chats/');
            setChats(response.data);
        } catch (error) {
            console.error("There was an error fetching the awaiting chats!", error);
        }
    };

    const fetchProgressChats = async () => {
      try {
          const response = await axiosInstance.get('get_progress_chats/');
          setInProgress(response.data);
      } catch (error) {
          console.error("There was an error fetching chat in progress!", error);
      }
    };

    fetchAwaitingChats();
    fetchProgressChats();
  }, [navigate]);

  const handleUserDetails = async (progress) => {
    const response = await axios.get(`http://127.0.0.1:8000/api/get_user_details/${progress.ticket_id}/`);
    setUserDetails(response.data);
    console.log(response.data);
    setShowPopup(true);
  };

  const handleAttend = async (chat) => {
    await axios.post(`http://127.0.0.1:8000/api/attend_to_chat/${chat.ticket_id}/`);
    setCurrentChat(chat);
    console.log(chat.ticket_id)
  };

  const handleGetUser = async (progress) => {
    const response = await axios.get(`http://127.0.0.1:8000/api/get_user_details/${progress.ticket_id}/`);
    setUserDetails(response.data);
  };

  const handleCloseGatewayPopup = () => {
    setShowPopup(false);
  };

  const handleAttendChat = (chat) => {
    handleAttend(chat);
    handleGetUser(chat);
  }

  const handleLogout = () => {
    sessionStorage.removeItem('token');
    navigate('/admin-login1');
  };

  return (
    <div className="admin-dashboard">
      <aside className="sidebar">
        <div className="sidebar-header">
          <h2>Admin Dashboard</h2>
        </div>
        <nav className="sidebar-nav">
          <ul>
            <li>
              <Link to="/admin-dashboard1">Home</Link>
            </li>
            <li>
              <Link to="/admin-chat1">Chats</Link>
            </li>
            <li>
              <Link to="/admin-settings">Settings</Link>
            </li>
          </ul>
        </nav>
        <div className="sidebar-footer">
          <button onClick={handleLogout}>Logout</button>
        </div>
      </aside>
      <main className="main-content">
        <header>
          <h1>Welcome, Admin</h1>
          <p>Select a tab to get started.</p>
        </header>

        <hr />

        <section>
          <h2>Awaiting Chats</h2>
          <ul className='chatroom-ul'>
              {chats.map(chat => (
                  <li key={chat._id} >
                      <button onClick={() => handleAttendChat(chat)} className='chatroom-assign-chat'>
                      <p>{chat.ticket_id}</p> <i className="fas fa-user" style={{cursor: "pointer"}} onClick={() => handleUserDetails(chat)}></i>
                      </button>
                  </li>
              ))}
          </ul>
        </section>
        <section>
          <h2>Chats In Progress</h2>
          <ul className='chatroom-ul'>
              {inProgress.map(progress => (
                  <li key={progress._id} >
                      <button onClick={() => handleAttendChat(progress)} className='chatroom-assign-chat'>
                        <p>{progress.ticket_id}</p> <i className="fas fa-user" style={{cursor: "pointer"}} onClick={() => handleUserDetails(progress)}></i>
                      </button>
                  </li>
              ))}
          </ul>
        </section>

        <GatewayPopup 
          show={showPopup} 
          handleClose={handleCloseGatewayPopup}
          content={
            <div>
                {userDetails && (
                  <div>
                    <p><b>Name: </b>{userDetails.name}</p>
                    <p><b>Email: </b>{userDetails.email}</p>
                    <p><b>Phone Number: </b>{userDetails.phone}</p>
                </div>
              )}
            </div>
          }/>

        {currentChat && userDetails && 
          <ChatBox 
            ticketId={currentChat.ticket_id} 
            ticketName={userDetails.name}
            ticketSubject={userDetails.subject} 
          />}
      </main>
    </div>
  );
}

export default AdminDashboard1;
