import React from "react";

const gatewayData = [
    {
      topic: "Alternative Internet Connectivity",
      subTopics: [
        {
          title: "Continuous Access",
          content: "Supports alternative internet connectivity for continuous smart home functionalities if the primary connection fails."
        },
        {
          title: "Reliable Performance",
          content: "Ensures reliability and consistent performance."
        }
      ]
    },
    {
      topic: "Enhanced Security",
      subTopics: [
        {
          title: "Cybersecurity",
          content: "Single cloud infrastructure reduces the risk of cyber attacks associated with multiple cloud systems."
        },
        {
          title: "Advanced Encryption",
          content: "Built-in firewall and advanced encryption protocols provide robust protection."
        }
      ]
    },
    {
        topic: "High Compatibility",
        subTopics: [
          {
            title: "Extensive Support:",
            content: "Integrates with Google Assistant, Alexa, Siri, Matter, Z-Wave, Zigbee, BLE, and more."
          },
          {
            title: "Third-Party Device Integration:",
            content: "Seamless control of a wide range of devices over 100,000."
          }
        ]
    },
    {
        topic: "Over-the-Air Updates",
        subTopics: [
          {
            title: "Regular Firmware Updates:",
            content: "Continuous enhancement of features and security."
          },
          {
            title: "Improved Functionality:",
            content: "Regular updates maintain optimal performance."
          }
        ]
    },
    {
        topic: "Energy Management",
        subTopics: [
          {
            title: "Optimization:",
            content: "Solutions to optimize energy use and reduce costs."
          },
          {
            title: "Consumption Monitoring:",
            content: "Real-time monitoring of energy consumption."
          }
        ]
    },
    {
        topic: "Easy Setup",
        subTopics: [
          {
            title: "Plug-and-Play:",
            content: "Simple installation process with step-by-step guidance."
          },
          {
            title: "User-Friendly Interface:",
            content: "Intuitive design for easy setup and use"
          }
        ]
    },
    {
        topic: "AI Integration",
        subTopics: [
          {
            title: "Smart Automation:",
            content: "AI for personalized automation and customizable voice commands."
          },
          {
            title: "Enhanced User Experience:",
            content: "AI-driven features for a smarter home environment."
          }
        ]
    },
    {
        topic: "Multi-Device Support",
        subTopics: [
          {
            title: "Versatile Management:",
            content: "Seamlessly manages multiple devices across different platforms."
          },
          {
            title: "Wide Range of Products:",
            content: "Supports a broad spectrum of smart home products."
          }
        ]
    },
    {
        topic: "User-Centric Design",
        subTopics: [
          {
            title: "Aesthetic and Functional:",
            content: "Products designed to suit user preferences with an intuitive interface."
          },
          {
            title: "Enhanced Usability:",
            content: "Focus on user experience and convenience."
          }
        ]
    },
    {
        topic: "Reliability and Durability",
        subTopics: [
          {
            title: "Robust Construction:",
            content: "Waterproof and durable design for long-lasting use."
          },
          {
            title: "Professional Support:",
            content: "Accredited and well-trained technicians for installation and support."
          }
        ]
    },
    {
        topic: "Comprehensive Tracking",
        subTopics: [
          {
            title: "Detailed Analytics:",
            content: "History of device connections and usage for better management."
          },
          {
            title: "Improved Management:",
            content: "Detailed tracking and analytics for enhanced control."
          }
        ]
    },
    // {
    //     topic: "",
    //     subTopics: [
    //       {
    //         title: "",
    //         content: ""
    //       },
    //       {
    //         title: "",
    //         content: ""
    //       }
    //     ]
    // },
];

const mobileAppData2 = [
    {
      title: "Intelligent Monitoring With AI:",
      content: "Advanced AI for monitoring and managing home environments, enhancing security and efficiency."
    },
    {
      title: "Local Control Without Internet:",
      content: "Operate your devices offline, ensuring continuous functionality and putting user privacy first by keeping data within the home network."
    },
    {
      title: "Real-Time Notifications:",
      content: "Receive instant alerts and updates about your smart home devices."
    },
    {
      title: "Automation and Scheduling:",
      content: "Easily automate routines and schedule device operations for convenience and efficiency."
    },
    {
      title: "Remote Access:",
      content: "Control your smart home devices from anywhere in the world."
    },
    {
      title: "User-Friendly Interface:",
      content: "Intuitive design for easy navigation and setup."
    },
    {
      title: "Voice Control Integration:",
      content: "Seamlessly integrates with Google Assistant, Alexa, and Siri for voice commands."
    },
    {
      title: "Energy Monitoring:",
      content: "Track and optimize energy usage to reduce costs."
    },
    {
      title: "Enhanced Security:",
      content: "Secure login and data encryption for protecting user information."
    },
    {
      title: "Customizable Settings:",
      content: "Personalize device settings and app interface to suit your preferences."
    },
    {
      title: "Over-the-Air Updates:",
      content: "Regular updates to enhance functionality and security of your smart home system."
    }
  ];
  

export const gatewayContent = ( 
    <div>
        <div>
            {gatewayData.map((section, index) => (
            <div key={index}>
                <p className="featureHead">{section.topic}</p>
                <ul className="bullet1">
                {section.subTopics.map((subTopic, subIndex) => (
                    <li key={subIndex}>
                    <span 
                    className="featureSubH">{subTopic.title}:
                    </span> 
                    {subTopic.content}
                    </li>
                ))}
                </ul>
            </div>
            ))}
        </div>

        <div>
        <p className="featureHead">Warranty and Support</p>
        <ul className="bullet1">
            

            <li>
            <span className="featureSubH">
            Product Warranty: 
                </span> 
                Warranty on all products for peace of mind.
            </li>
            <li>
            <span className="featureSubH">
            Customer Support: 
            </span>
            24/7 customer support to assist with any issues.
            <br />

            <span className="featureSubH" >
            
            These features make the GrandeurSmart Home Gateway a comprehensive, secure, and user-friendly solution for managing and automating your smart home ecosystem.

            </span>

            </li>

        
        </ul>
        </div>
    </div>
  );


export const mobileAppContent = (
    <div>
        
        <div className="features">
            <div>
                {mobileAppData2.map((item, index) => (
                    <ul className="bullet1" key={index}>
                        <li>
                            <span className="featureSubH">
                                {item.title}
                            </span>
                            {item.content}
                        </li>
                    </ul>
                ))}
            </div>
        </div>
    </div>
);

export const productPackage = (
  <div>
    
  </div>
)



