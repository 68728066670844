import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate, Link } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function AdminSignup1() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    staff_number: '',
    password: '',
    confirm_password: '',
    passcode: ''
  });

  const [showPassword, setShowPassword] = useState(false);
  const [passwordStrength, setPasswordStrength] = useState(0); // 0: weak, 1: medium, 2: strong
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    // Check password strength
    if (name === 'password') {
      const strength = calculatePasswordStrength(value);
      setPasswordStrength(strength);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Basic form validation
    if (formData.password !== formData.confirm_password) {
      toast.error('Passwords do not match');
      return;
    }

    try {
      const response = await axios.post('http://127.0.0.1:8000/api/admin/signup/', formData);
      toast.success(response.data.message);
      if (response.data.token) {
        sessionStorage.setItem('token', response.data.token);
        navigate('/admin-dashboard1');
      }
    } catch (error) {
      if (error.response && error.response.data) {
        toast.error(error.response.data.error);
      } else {
        toast.error('An error occurred. Please try again.');
      }
    }
  };

  const calculatePasswordStrength = (password) => {
    // Implement your password strength logic here
    // Example logic: check for length and character types
    if (password.length >= 8 && /[a-z]/.test(password) && /[A-Z]/.test(password) && /[0-9]/.test(password) && /[^A-Za-z0-9]/.test(password)) {
      return 2; // Strong password
    } else if (password.length >= 6 && /[a-z]/.test(password) && /[A-Z]/.test(password) && /[0-9]/.test(password)) {
      return 1; // Medium password
    } else {
      return 0; // Weak password
    }
  };

  const getProgressBarColor = () => {
    if (passwordStrength === 2) {
      return "bg-success"; // Green for strong
    } else if (passwordStrength === 1) {
      return "bg-warning"; // Yellow for medium
    } else {
      return "bg-danger"; // Red for weak
    }
  };

  return (
    <div>
      <ToastContainer />

      <section className="vh-100" style={{ backgroundColor: "#ffffff" }}>
        <div className="container py-5 h-100">
          <div className="row d-flex justify-content-center align-items-center h-100">
            <div className="col col-xl-20">
              <div className="card" style={{ borderRadius: "1rem" }}>
                <div className="row g-0">
                  <div className="col-md-6 col-lg-5 d-none d-md-block">
                    <img
                      src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-login-form/img1.webp"
                      alt="login form"
                      className="img-fluid"
                      style={{ borderRadius: "1rem 0 0 1rem" }}
                    />
                  </div>
                  <div className="col-md-6 col-lg-7 d-flex align-items-center">
                    <div className="card-body p-4 p-lg-5 text-black">
                      <form onSubmit={handleSubmit}>
                        <div className="d-flex align-items-center mb-3 pb-1">
                          <span className="h2 fw-bold mb-0">
                            Welcome to <b>Grandeursmart Admin👋</b>
                          </span>
                        </div>
                        <h2
                          className="fw-normal mb-3 pb-3"
                          style={{ letterSpacing: 1 }}
                        >
                          Sign Up
                        </h2>
                        <div className="form-outline mb-4">
                          <input
                            type="text"
                            name="name"
                            placeholder="Name"
                            value={formData.name}
                            onChange={handleChange}
                            className="form-control form-control-lg"
                            required
                          />
                        </div>
                        <div className="form-outline mb-4">
                          <input
                            type="email"
                            name="email"
                            placeholder="Email"
                            value={formData.email}
                            onChange={handleChange}
                            className="form-control form-control-lg"
                            required
                          />
                        </div>
                        <div className="form-outline mb-4">
                          <input
                            type="text"
                            name="phone"
                            placeholder="Phone"
                            value={formData.phone}
                            onChange={handleChange}
                            className="form-control form-control-lg"
                            required
                          />
                        </div>
                        <div className="form-outline mb-4">
                          <input
                            type="text"
                            name="staff_number"
                            placeholder="Staff Number"
                            value={formData.staff_number}
                            onChange={handleChange}
                            className="form-control form-control-lg"
                            required
                          />
                        </div>
                        <div className="form-outline mb-4">
                          <input
                            type={showPassword ? "text" : "password"}
                            name="password"
                            placeholder="Password"
                            value={formData.password}
                            onChange={handleChange}
                            className="form-control form-control-lg"
                            required
                          />
                          <div className="progress mb-1" style={{height: "2.5px"}}>
                            <div
                              className={`progress-bar ${getProgressBarColor()}`}
                              role="progressbar"
                              style={{ width: `${(passwordStrength + 1) * 33.33}%` }}
                              aria-valuenow={passwordStrength + 1}
                              aria-valuemin="0"
                              aria-valuemax="3"
                            ></div>
                          </div>
                          <button
                            type="button"
                            onClick={() => setShowPassword(!showPassword)}
                            style={{
                              backgroundColor: "white",
                              color: "#A97D5D",
                              fontSize: "small",
                              fontWeight: "bold"
                            }}
                          >
                            {showPassword ? "Hide" : "Show"}
                          </button>
                        </div>
                        <div className="form-outline mb-4">
                          <input
                            type={showPassword ? "text" : "password"}
                            name="confirm_password"
                            placeholder="Confirm Password"
                            value={formData.confirm_password}
                            onChange={handleChange}
                            className="form-control form-control-lg"
                            required
                          />
                        </div>
                        <div className="form-outline mb-4">
                          <input
                            type="text"
                            name="passcode"
                            placeholder="Passcode"
                            value={formData.passcode}
                            onChange={handleChange}
                            className="form-control form-control-lg"
                            required
                          />
                        </div>
                        
                        <div className="pt-1 mb-4">
                          <button
                            className="btn btn-lg btn-block"
                            type="submit"
                            style={{ backgroundColor: "#5C755E", color: 'white' }}
                          >
                            Sign Up
                          </button>
                        </div>
                        <p className="mb-5 pb-lg-2" style={{ color: "#A97D5D" }}>
                          Already have an account?{" "}
                          <Link to="/admin-login1" style={{ color: "#A97D5D" }}>
                            Login Now
                          </Link>
                        </p>
                        <a href="#!" className="small text-muted">
                          Terms of use.
                        </a>
                        <a href="#!" className="small text-muted">
                          Privacy policy
                        </a>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default AdminSignup1;
