import React, { useState, useEffect } from 'react';
import { Carousel, Button } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { EstatePackageData } from '../components/ProductsData';
import '../EstatePlansCarousel.css';
import { useCart } from '../contexts/CartContext';

const EstatePlansCarousel = () => {
    const [currentDescriptionIndex, setCurrentDescriptionIndex] = useState(0);
    const { addToCart } = useCart(); // Use the cart context
    const navigate = useNavigate(); 

    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentDescriptionIndex(prevIndex => {
                const nextIndex = prevIndex + 1;
                return nextIndex < EstatePackageData[0].description.length ? nextIndex : 0;
            });
        }, 3000); // Change description every 3 seconds

        return () => clearInterval(intervalId); // Clean up the interval on component unmount
    }, []);

    function showAlert(estatePlan) {
        toast.success(`${estatePlan.name} has been added to your cart!`); // Use 'success' type for positive notifications
    };

    const handleAddToCart = (arg) => {
        addToCart(arg);
        showAlert(arg);
        navigate('/cart'); // Redirect to cart page
    };

    return (
        <div className="carousel-video-container">
            <video 
                autoPlay 
                muted 
                playsInline
                loop 
                className="background-video">
                <source src="assets/Estate-Videos/Real-Estate.mp4" type="video/mp4" />
                Your browser does not support the video tag.
            </video>
            <Carousel fade className="carousel-content">
                {EstatePackageData.map((estatePlan) => {
                    // Get the first image URL dynamically from the images object
                    const firstImageUrl = estatePlan.images[0];
                    
                    return (
                        <Carousel.Item key={estatePlan.id}>
                            <div className="carousel-item-content">
                                <div className="carousel-image-container">
                                    <img
                                        className="carousel-image"
                                        src={firstImageUrl}
                                        alt={estatePlan.name}
                                    />
                                </div>
                                <div className="carousel-caption-container">
                                    <Carousel.Caption className="carousel-caption">
                                        <h2>{estatePlan.name}</h2>
                                        <p key={currentDescriptionIndex} className="animated-description">
                                            {estatePlan.description[currentDescriptionIndex]}
                                        </p>
                                        <div className="carousel-buttons">
                                            {/* <Link to={`/estate-plans/${estatePlan.id}/buy`}>
                                                <Button variant="primary" className="carousel-button buy-button">Buy</Button>
                                            </Link> */}
                                            <Button 
                                                variant="secondary" 
                                                className="carousel-button customize-button" 
                                                onClick={() => handleAddToCart(estatePlan)}
                                            >
                                                Get Now!
                                            </Button>
                                        </div>
                                    </Carousel.Caption>
                                </div>
                            </div>
                        </Carousel.Item>
                    );
                })}
            </Carousel>
        </div>
    );
};

export default EstatePlansCarousel;
