// import React, { useState, useEffect, useRef } from 'react';
// import { Link, useNavigate } from 'react-router-dom';
// import { toast } from 'react-toastify';
// import GatewayPopup from './GatewayPopup';
// import ItemPopup from './ItemPopup';
// import StarRatingComponent from 'react-star-rating-component';
// import { getItemsByIds } from '../utility/utils';
// import { useCart } from '../contexts/CartContext';
// import '../productStyle.css';

// const Product = ({ product }) => {
//     const [showPopup, setShowPopup] = useState(false);
//     const [selectedItem, setSelectedItem] = useState(null);
//     const [showItemsDropdown, setShowItemsDropdown] = useState(false);
//     const [rating, setRating] = useState(0);
//     const [comment, setComment] = useState('');
//     const [userRated, setUserRated] = useState(false);
//     const [userComment, setUserComment] = useState('');
//     const { addToCart } = useCart(); // Use the cart context
//     const navigate = useNavigate(); // Use navigate for redirecting
//     const [selectedPackage, setSelectedPackage] = useState('Basic'); // Default to 'Basic'

//     const dropdownRef = useRef(null);

//     const handleOpenPopup = () => setShowPopup(true);
//     const handleClosePopup = () => setShowPopup(false);
//     const handleItemClick = (item) => setSelectedItem(item);
//     const handleCloseItemPopup = () => setSelectedItem(null);
//     const handleStarClick = (value) => {
//         setRating(value);
//         setUserRated(true);
//     };
//     const handleCommentChange = (event) => {
//         setComment(event.target.value);
//     };
//     const handleCommentSubmit = () => {
//         console.log('Rating:', rating);
//         console.log('Comment:', comment);
//         localStorage.setItem(`product_${product.id}_rating`, rating);
//         localStorage.setItem(`product_${product.id}_comment`, comment);
//         setUserComment(comment);
//         window.location.href = 'https://play.google.com/store/apps/details?id=com.grandeursmart.home.android';
//     };

//     function showAlert(product, selectedPackage) {
//         toast.success(`${product.name} (${selectedPackage}) has been added to your cart!`); // Include the selected package in the alert
//     }

//     const handleAddToCart = () => {
//         addToCart(product, selectedPackage); // Pass the selected package to addToCart
//         showAlert(product, selectedPackage);
//         navigate('/cart'); // Redirect to cart page
//     };


//     const handleCustomizeKit = () => {
//         addToCart(product, selectedPackage);
//         showAlert(product, selectedPackage);
//         navigate(`/customize/${product.id}`);
//     };

//     useEffect(() => {
//         const userRating = localStorage.getItem(`product_${product.id}_rating`);
//         const userComment = localStorage.getItem(`product_${product.id}_comment`);
//         if (userRating) {
//             setRating(parseFloat(userRating));
//             setUserRated(true);
//             setUserComment(userComment);
//         }
//     }, [product.id]);

//     useEffect(() => {
//         const handleKeyDown = (event) => {
//             if (event.key === 'Escape') {
//                 handleClosePopup();
//                 handleCloseItemPopup();
//             }
//         };

//         document.addEventListener('keydown', handleKeyDown);

//         // Cleanup event listener on component unmount
//         return () => {
//             document.removeEventListener('keydown', handleKeyDown);
//         };
//     }, []);

//     const packageData = product[selectedPackage];
//     const description = packageData.description.join('\n');
//     const descriptionLines = description.split('\n');
//     const truncatedDescription = descriptionLines.length > 1 ? descriptionLines[0] + '...' : descriptionLines[0];
//     const items = getItemsByIds(packageData.items);
//     const shouldShowReadMoreItems = items.length > 4;
//     const shouldShowReadMore = descriptionLines.length > 1;

//     const truncatedItems = shouldShowReadMoreItems ? items.slice(0, 4) : items;
//     const remainingItems = shouldShowReadMoreItems ? items.slice(4) : [];

//     const availablePackages = Object.keys(product).filter(key => key !== 'id' && key !== 'name');

//     const carouselRef = useRef(null);
//     const [currentSlide, setCurrentSlide] = useState(0);

//     const imageArray = Object.values(packageData.images);

//     useEffect(() => {
//         const interval = setInterval(() => {
//             setCurrentSlide((prevSlide) => (prevSlide + 1) % imageArray.length);
//         }, 10000); //Change slide every 10 seconds

//         return () => clearInterval(interval);
//     }, [imageArray.length]);

//     const handleNextSlide = (e) => {
//         e.stopPropagation();
//         setCurrentSlide((prevSlide) => (prevSlide + 1) % imageArray.length);
//     };

//     const handlePrevSlide = (e) => {
//         e.stopPropagation();
//         setCurrentSlide((prevSlide) => (prevSlide - 1 + imageArray.length) % imageArray.length);
//     };

//     const handleSlideClick = () => {
//         setCurrentSlide((prevSlide) => (prevSlide + 1) % imageArray.length);        
//     }

//     return (
//         <div className="packag">
//             <canvas id="particleCanvas1" className="particle-canvas"></canvas>
            
//             <div className="left">
//                 <div className="carouse" ref={carouselRef} onClick={handleSlideClick}>
//                     <div className="carouse-wrapper" style={{ transform: `translateX(-${currentSlide * 100}%)` }}>
//                         {imageArray.map((image, index) => (
//                             <div key={index} className={`slid ${currentSlide === index ? 'active' : ''}`}>
//                                 <img src={image} alt={`Product Kit ${index}`} />
//                             </div>
//                         ))}
//                     </div>
//                     <button className="carouse-control prev" onClick={handlePrevSlide}>&lt;</button>
//                     <button className="carouse-control next" onClick={handleNextSlide}>&gt;</button>
//                 </div>
//             </div>

//             <div className="right">
//                 <div className="product-text">
//                     <h2>{product.name}</h2>
//                     {/* <hr /> */}

//                     <div className="sub-package-buttons">
//                         {availablePackages.map(pkg => (
//                             <button
//                                 key={pkg}
//                                 onClick={() => setSelectedPackage(pkg)}
//                                 className={`sub-package-button ${selectedPackage === pkg ? 'active' : ''}`}
//                             >
//                                 {pkg}
//                             </button>
//                         ))}
//                     </div>
//                     <h4 style={{ textAlign: 'left', fontWeight: 'bolder', fontSize: '1.5rem', }}>
//                     ${packageData.amount.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
//                     </h4>
//                     <div className="star-rating">
//                         {userRated ? (
//                             <>
//                                 <StarRatingComponent
//                                     name="productRating"
//                                     starCount={5}
//                                     value={5.0}
//                                     onStarClick={handleStarClick}
//                                     starColor={'#5C755E'}
//                                     className="small-stars"
//                                 />
//                                 <p className='star-p'>5.0</p>
//                             </>
//                         ) : (
//                             <StarRatingComponent
//                                 name="productRating"
//                                 starCount={5}
//                                 value={5}
//                                 onStarClick={handleStarClick}
//                                 starColor={'#5C755E'}
//                                 className="small-stars"
//                             />
//                         )}
//                     </div>
//                     <span className='featureHead'>Items:</span>
//                     <ul>
//                         {truncatedItems.map((item, index) => (
//                             <li key={item.id}
//                                 style={{ cursor: 'pointer', }}
//                                 onClick={() => handleItemClick(item)}>
//                                 {item.name}
//                             </li>
//                         ))}
//                         {shouldShowReadMoreItems && (
//                             <li
//                                 className="read-more"
//                                 onClick={() => setShowItemsDropdown(!showItemsDropdown)}
//                                 ref={dropdownRef}
//                             >
//                                 {remainingItems.length} items remaining <span className="dropdown-symbol">&#9660;</span>
//                             </li>
//                         )}
//                     </ul>
//                     {showItemsDropdown && (
//                         <ul className="remaining-items-dropdown" style={{ top: dropdownRef.current.offsetTop + 20, left: dropdownRef.current.offsetLeft }}>
//                             {remainingItems.map((item) => (
//                                 <li key={item.id} onClick={() => handleItemClick(item)}>
//                                     {item.name}
//                                 </li>
//                             ))}
//                         </ul>
//                     )}
//                     <p style={{ whiteSpace: 'pre-wrap' }}> {/* Ensure newlines are rendered */}
//                         <span className='featureHead'>Description:</span>
//                         <br />
//                         <ol>
//                             <li>
//                                 {shouldShowReadMore ? truncatedDescription : description}
//                             </li>
//                         </ol>
//                     </p>
//                     {shouldShowReadMore && (
//                         <div>
//                             <Link className="read-more" onClick={() => handleOpenPopup()}>
//                                 See Full Details...
//                             </Link>
//                         </div>
//                     )}
//                     <hr />
//                     <div className="pricing">
//                         <div>
//                             <h5 className="customizeKit" onClick={handleAddToCart}>
//                                 Customize Kit
//                             </h5>
//                         </div>
//                         <div>
//                             <p className="buy-link" onClick={handleAddToCart}>
//                                 Add to Cart
//                             </p>
//                         </div>
//                     </div>
//                     <GatewayPopup
//                         show={showPopup}
//                         handleClose={handleClosePopup}
//                         content={
//                             <div>
//                                 <span className="featureHead">Items:</span>
//                                 <ul>
//                                     {items.map(item => (
//                                         <li key={item.id}>{item.name}</li>
//                                     ))}
//                                 </ul>
//                                 <p style={{ whiteSpace: 'pre-wrap' }}>
//                                     <span className='featureHead'>Description:</span>
//                                     <br />
//                                     <ol>
//                                             {packageData.description.map((description, index) => (
//                                                 <li key={index}>{description}</li>
//                                             ))}
//                                     </ol>
//                                 </p>

//                                 {packageData.features && (
//                                     <>
//                                         <span className='featureHead'>Features:</span>
//                                         <ul>
//                                             {packageData.features.map((feature, index) => (
//                                                 <li key={index}>{feature}</li>
//                                             ))}
//                                         </ul>
//                                     </>
//                                 )}

//                                 {packageData.benefits && (
//                                     <>
//                                         <span className='featureHead'>Benefits:</span>
//                                         <ul>
//                                             {packageData.benefits.map((benefit, index) => (
//                                                 <li key={index}>{benefit}</li>
//                                             ))}
//                                         </ul>
//                                     </>
//                                 )}

//                                 {packageData.applications && (
//                                     <>
//                                         <span className='featureHead'>Applications:</span>
//                                         <ul>
//                                             {packageData.applications.map((application, index) => (
//                                                 <li key={index}>{application}</li>
//                                             ))}
//                                         </ul>
//                                     </>
//                                 )}

//                                 <div>
//                                     <h3>Rate and Comment</h3>
//                                     <div className="star-rating">
//                                         <StarRatingComponent
//                                             name="commentRating"
//                                             starCount={5}
//                                             value={rating}
//                                             onStarClick={handleStarClick}
//                                             starColor={'#5C755E'}
//                                             className="small-stars"
//                                         />
//                                         <p className='star-p'>{rating}</p>
//                                     </div>
//                                     <textarea
//                                         rows="4"
//                                         cols="50"
//                                         placeholder="Enter your comment here..."
//                                         value={comment}
//                                         onChange={handleCommentChange}
//                                     ></textarea>
//                                     <div className="buy">
//                                         <button className='button-link' onClick={handleCommentSubmit}>Submit</button>
//                                     </div>
//                                 </div>
//                                 {userRated && userComment && (
//                                     <div>
//                                         <h3>Your Review</h3>
//                                         <div className="star-rating">
//                                             <StarRatingComponent
//                                                 name="userRating"
//                                                 starCount={5}
//                                                 value={rating}
//                                                 onStarClick={handleStarClick}
//                                                 starColor={'#5C755E'}
//                                                 className="small-stars"
//                                             />
//                                             <p className='star-p'>{rating}</p>
//                                         </div>
//                                         <p>{userComment}</p>
//                                     </div>
//                                 )}
//                             </div>
//                         }
//                     />
//                     <ItemPopup
//                         show={!!selectedItem}
//                         handleClose={handleCloseItemPopup}
//                         item={selectedItem}
//                     />
//                     {/* {selectedItem && (
//                         <ItemPopup
//                             item={selectedItem}
//                             onClose={handleCloseItemPopup}
//                         />
//                     )} */}
//                 </div>
//             </div>
//         </div>
//     );
// };

// export default Product;

import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import GatewayPopup from './GatewayPopup';
import ItemPopup from './ItemPopup';
import { getItemsByIds } from '../utility/utils';
import { useCart } from '../contexts/CartContext';
import '../productStyle.css';

const Product = ({ product }) => {
    const [showPopup, setShowPopup] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const [showItemsDropdown, setShowItemsDropdown] = useState(false);
    const [comment, setComment] = useState('');
    const { addToCart } = useCart();
    const navigate = useNavigate();
    const [selectedPackage, setSelectedPackage] = useState('Basic');

    const dropdownRef = useRef(null);

    const handleOpenPopup = () => setShowPopup(true);
    const handleClosePopup = () => setShowPopup(false);
    const handleItemClick = (item) => setSelectedItem(item);
    const handleCloseItemPopup = () => setSelectedItem(null);
    const handleCommentChange = (event) => setComment(event.target.value);
    const handleCommentSubmit = () => {
        console.log('Rating:', 5.0);
        console.log('Comment:', comment);
        localStorage.setItem(`product_${product.id}_comment`, comment);
        window.location.href = 'https://play.google.com/store/apps/details?id=com.grandeursmart.home.android';
    };

    function showAlert(product, selectedPackage) {
        toast.success(`${product.name} (${selectedPackage}) has been added to your cart!`);
    }

    const handleAddToCart = () => {
        addToCart(product, selectedPackage);
        showAlert(product, selectedPackage);
        navigate('/cart');
    };

    const handleCustomizeKit = () => {
        addToCart(product, selectedPackage);
        showAlert(product, selectedPackage);
        navigate(`/customize/${product.id}`);
    };

    useEffect(() => {
        const userComment = localStorage.getItem(`product_${product.id}_comment`);
        if (userComment) {
            setComment(userComment);
        }
    }, [product.id]);

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'Escape') {
                handleClosePopup();
                handleCloseItemPopup();
            }
        };

        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    const packageData = product[selectedPackage];
    const description = packageData.description.join('\n');
    const descriptionLines = description.split('\n');
    const truncatedDescription = descriptionLines.length > 1 ? descriptionLines[0] + '...' : descriptionLines[0];
    const items = getItemsByIds(packageData.items);
    const shouldShowReadMoreItems = items.length > 4;
    const shouldShowReadMore = descriptionLines.length > 1;

    const truncatedItems = shouldShowReadMoreItems ? items.slice(0, 4) : items;
    const remainingItems = shouldShowReadMoreItems ? items.slice(4) : [];

    const availablePackages = Object.keys(product).filter(key => key !== 'id' && key !== 'name');

    const carouselRef = useRef(null);
    const [currentSlide, setCurrentSlide] = useState(0);

    const imageArray = Object.values(packageData.images);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentSlide((prevSlide) => (prevSlide + 1) % imageArray.length);
        }, 10000);

        return () => clearInterval(interval);
    }, [imageArray.length]);

    const handleNextSlide = (e) => {
        e.stopPropagation();
        setCurrentSlide((prevSlide) => (prevSlide + 1) % imageArray.length);
    };

    const handlePrevSlide = (e) => {
        e.stopPropagation();
        setCurrentSlide((prevSlide) => (prevSlide - 1 + imageArray.length) % imageArray.length);
    };

    const handleSlideClick = () => {
        setCurrentSlide((prevSlide) => (prevSlide + 1) % imageArray.length);        
    }

    return (
        <div className="packag">
            <canvas id="particleCanvas1" className="particle-canvas"></canvas>
            
            <div className="left">
                <div className="carouse" ref={carouselRef} onClick={handleSlideClick}>
                    <div className="carouse-wrapper" style={{ transform: `translateX(-${currentSlide * 100}%)` }}>
                        {imageArray.map((image, index) => (
                            <div key={index} className={`slid ${currentSlide === index ? 'active' : ''}`}>
                                <img src={image} alt={`Product Kit ${index}`} />
                            </div>
                        ))}
                    </div>
                    <button className="carouse-control prev" onClick={handlePrevSlide}>&lt;</button>
                    <button className="carouse-control next" onClick={handleNextSlide}>&gt;</button>
                </div>
            </div>

            <div className="right">
                <div className="product-text">
                    <h2>{product.name}</h2>

                    <div className="sub-package-buttons">
                        {availablePackages.map(pkg => (
                            <button
                                key={pkg}
                                onClick={() => setSelectedPackage(pkg)}
                                className={`sub-package-button ${selectedPackage === pkg ? 'active' : ''}`}
                            >
                                {pkg}
                            </button>
                        ))}
                    </div>
                    <h4 style={{ textAlign: 'left', fontWeight: 'bolder', fontSize: '1.5rem', }}>
                    ${packageData.amount.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                    </h4>
                    <div className="star-rating">
                        <span>5.0 ★★★★★</span>
                    </div>
                    <span className='featureHead'>Items:</span>
                     <ul>
                         {truncatedItems.map((item, index) => (
                            <li key={item.id}
                                style={{ cursor: 'pointer', }}
                                onClick={() => handleItemClick(item)}>
                                {item.name}
                            </li>
                        ))}
                        {shouldShowReadMoreItems && (
                            <li
                                className="read-more"
                                onClick={() => setShowItemsDropdown(!showItemsDropdown)}
                                ref={dropdownRef}
                            >
                                {remainingItems.length} items remaining <span className="dropdown-symbol">&#9660;</span>
                            </li>
                        )}
                    </ul>
                    {showItemsDropdown && (
                        <ul className="remaining-items-dropdown" style={{ top: dropdownRef.current.offsetTop + 20, left: dropdownRef.current.offsetLeft }}>
                            {remainingItems.map((item) => (
                                <li key={item.id} onClick={() => handleItemClick(item)}>
                                    {item.name}
                                </li>
                            ))}
                        </ul>
                    )}
                    <p style={{ whiteSpace: 'pre-wrap' }}>
                        <span className='featureHead'>Description:</span>
                        <br />
                        <ol>
                            <li>
                                {shouldShowReadMore ? truncatedDescription : description}
                            </li>
                        </ol>
                    </p>
                    {shouldShowReadMore && (
                        <div>
                            <Link className="read-more" onClick={() => handleOpenPopup()}>
                                See Full Details...
                            </Link>
                        </div>
                    )}
                    <hr />
                    <div className="pricing">
                        <div>
                            <h5 className="customizeKit" onClick={handleAddToCart}>
                                Customize Kit
                            </h5>
                        </div>
                        <div>
                            <p className="buy-link" onClick={handleAddToCart}>
                                Add to Cart
                            </p>
                        </div>
                    </div>
                    {shouldShowReadMore && (
                        <div>
                              <GatewayPopup
                                show={showPopup}
                                handleClose={handleClosePopup}
                                content={
                                    <div>
                                        <span className="featureHead">Items:</span>
                                        <ul>
                                            {items.map(item => (
                                                <li key={item.id}>{item.name}</li>
                                            ))}
                                        </ul>
                                        <p>
                                            <span className='featureHead'>Description:</span>
                                            <br />
                                            <ol>
                                                {packageData.description.map((description, index) => (
                                                    <li key={index}>{description}</li>
                                                ))}
                                            </ol>
                                        </p>
                                        {packageData.features && (
                                            <>
                                                <span className='featureHead'>Features:</span>
                                                <ul>
                                                    {packageData.features.map((feature, index) => (
                                                        <li key={index}>{feature}</li>
                                                    ))}
                                                </ul>
                                            </>
                                        )}
                                        {packageData.benefits && (
                                            <>
                                                <span className='featureHead'>Benefits:</span>
                                                <ul>
                                                    {packageData.benefits.map((benefit, index) => (
                                                        <li key={index}>{benefit}</li>
                                                    ))}
                                                </ul>
                                            </>
                                        )}
                                        {packageData.applications && (
                                            <>
                                                <span className='featureHead'>Applications:</span>
                                                <ul>
                                                    {packageData.applications.map((application, index) => (
                                                        <li key={index}>{application}</li>
                                                    ))}
                                                </ul>
                                            </>
                                        )}
                                        <div>
                                            <h3>Rating: 5.0 </h3>
                                            
                                        </div>
                                        
                                        <textarea
                                            rows="4"
                                            cols="50"
                                            placeholder="Add your comment here..."
                                            value={comment}
                                            onChange={handleCommentChange}
                                            
                                        ></textarea>
                                        <div className="buy">
                                            <button className='button-link' onClick={handleCommentSubmit}>Submit</button>
                                        </div>
                                    </div>
                                }
                            />
                        </div>
                    )}
                </div>
            </div>
            {selectedItem && (
                <ItemPopup
                    show={!!selectedItem}
                    handleClose={handleCloseItemPopup}
                    item={selectedItem}
                />
            )}
        </div>
    );
};

export default Product;
