import React, { useEffect, useRef, useCallback } from 'react';

const GatewayPopup = ({ show, handleClose, content }) => {
  const popupRef = useRef(null);

  const handleClickOutside = useCallback((event) => {
    if (popupRef.current && !popupRef.current.contains(event.target)) {
      handleClose();
    }
  }, [handleClose]);

  useEffect(() => {
    if (show) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [show, handleClickOutside]);

  const popupStyle = {
    display: show ? 'flex' : 'none',
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 1000,
  };

  const innerStyle = {
    position: 'relative',
    padding: '20px',
    width: '80%',
    maxWidth: '600px',
    maxHeight: '80%',
    backgroundColor: 'white',
    borderRadius: '10px',
    boxShadow: '0 5px 15px rgba(0, 0, 0, 0.3)',
    overflowY: 'auto', // Enable vertical scrolling
  };

  const closeBtnStyle = {
    position: 'sticky',
    top: '5px',
    right: '10px',
    left: '50',
    backgroundColor: 'none',
    border: 'none',
    cursor: 'pointer',
    fontFamily: 'Arial',
    color: '#A97D5D',
    fontSize: 'large',
  };

  return (
    <div style={popupStyle}>
      <div style={innerStyle} ref={popupRef}>
        <button style={closeBtnStyle} onClick={handleClose}>x</button>
        {content}
      </div>
    </div>
  );
};

export default GatewayPopup;
