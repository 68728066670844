import React, { useState, useEffect } from 'react';
import axios from 'axios';

const ExchangeRateConverter = () => {
  const [oandaRate, setOandaRate] = useState(null);
  const [abokifxRate, setAbokifxRate] = useState(null);
  const [bestRate, setBestRate] = useState(null);
  const [adjustedRate, setAdjustedRate] = useState(null);
  const [amountInUSD, setAmountInUSD] = useState(100); // Example amount in USD
  const [amountInNaira, setAmountInNaira] = useState(null);
  
  const publicKey = process.env.REACT_APP_PAYSTACK_TEST_PUBLIC_KEY;
    const oandaUrl = process.env.REACT_APP_OANDA_API_URL;
    const oandaKey = process.env.REACT_APP_OANDA_API_KEY;
    const abokifxKey = process.env.REACT_APP_ABOKIFX_API_ENDPOINT;

    

    useEffect(() => {
        const fetchOandaRate = async () => {
          try {
            const response = await axios.get(`${oandaUrl}`, {
                headers: {
                  'Authorization': `Bearer ${oandaKey}`
                }
            });
            setOandaRate(response.data.rate);
          } catch (error) {
            console.error('Failed to fetch Oanda rate:', error);
          }
        };
    
        const fetchAbokifxRate = async () => {
          try {
            const response = await axios.get(`${abokifxKey}`);
            setAbokifxRate(response.data.rate);
          } catch (error) {
            console.error('Failed to fetch Abokifx rate:', error);
          }
        };
    
        fetchOandaRate();
        fetchAbokifxRate();
      }, []);

  useEffect(() => {
    if (oandaRate && abokifxRate) {
      setBestRate(Math.max(oandaRate, abokifxRate));
    } else if (oandaRate) {
      setBestRate(oandaRate);
    } else if (abokifxRate) {
      setBestRate(abokifxRate);
    }
  }, [oandaRate, abokifxRate]);

  useEffect(() => {
    if (bestRate) {
      const adjusted = bestRate * 1.15;
      setAdjustedRate(adjusted);
      setAmountInNaira(amountInUSD * adjusted);
    }
  }, [bestRate]);

  return (
    <div>
      <h1>Exchange Rate Converter</h1>
      <p>Oanda Rate: {oandaRate ? oandaRate : 'Loading...'}</p>
      <p>Abokifx Rate: {abokifxRate ? abokifxRate : 'Loading...'}</p>
      <p>Best Rate: {bestRate ? bestRate : 'Calculating...'}</p>
      <p>Adjusted Rate: {adjustedRate ? adjustedRate : 'Calculating...'}</p>
      <p>Amount in USD: {amountInUSD}</p>
      <p>Amount in Naira (after adjustment): {amountInNaira ? amountInNaira : 'Calculating...'}</p>
    </div>
  );
};

export default ExchangeRateConverter;
