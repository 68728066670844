import React from "react";
import { Link } from "react-router-dom";

function UserDashboard() {
    return (
        <div>
            <h1>
                User Dashboard
            </h1>

            <nav>
                <ul>
                    <li>
                        <Link to="/user/profile"> Home </Link>
                    </li>
                    <li>
                        <Link to="/user/content"> Product</Link>
                    </li>
                    <li>
                        <Link to="/user/Blog">Integration</Link>
                    </li>
                    <li>
                        <Link to="/user/Blog">Blog</Link>
                    </li>
                    <li>
                        <Link to="/user/Blog"> Profile </Link>
                    </li>
                </ul>
            </nav>

            <div>
                <h2>
                    Welcome to Your Dashboard
                </h2>
                <p>
                    Do A Lot Here
                </p>
            </div>
        </div>
    );
}

export default UserDashboard;