import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import GatewayPopup from './GatewayPopup';
import ItemPopup from './ItemPopup';
import StarRatings from 'react-star-ratings';
import { getItemsByIds } from '../utility/utils';
import { useCart } from '../contexts/CartContext';
import '../productStyle.css';

const EstatePlan = ({ estatePlan }) => {
    const [showPopup, setShowPopup] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const [showItemsDropdown, setShowItemsDropdown] = useState(false);
    const [rating, setRating] = useState(0);
    const [comment, setComment] = useState('');
    const [userRated, setUserRated] = useState(false);
    const [userComment, setUserComment] = useState('');
    const { addToCart } = useCart(); // Use the cart context
    const navigate = useNavigate(); // Use navigate for redirecting

    const dropdownRef = useRef(null);

    const handleOpenPopup = () => setShowPopup(true);
    const handleClosePopup = () => setShowPopup(false);
    const handleItemClick = (item) => setSelectedItem(item);
    const handleCloseItemPopup = () => setSelectedItem(null);
    const handleCommentChange = (event) => {
        setComment(event.target.value);
    };
    const handleCommentSubmit = () => {
        console.log('Comment:', comment);
        localStorage.setItem(`product_${estatePlan.id}_comment`, comment);
        setUserComment(comment);
        window.location.href = 'https://play.google.com/store/apps/details?id=com.grandeursmart.home.android';
    };

    function showAlert(estatePlan) {
        toast.success(`${estatePlan.name} has been added to your cart!`); // Use 'success' type for positive notifications
    };

    const handleAddToCart = () => {
        addToCart(estatePlan);
        showAlert(estatePlan);
        navigate('/cart'); // Redirect to cart page
    };

    useEffect(() => {
        const userComment = localStorage.getItem(`product_${estatePlan.id}_comment`);
        setUserComment(userComment);
    }, [estatePlan.id]);

    const handleCustomizeKit = () => {
        addToCart(estatePlan);
        showAlert(estatePlan);
        navigate(`/customize-estate/${estatePlan.id}`); // Redirect to customize kit page
    };

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'Escape') {
                handleClosePopup();
                handleCloseItemPopup();
            }
        };

        document.addEventListener('keydown', handleKeyDown);

        // Cleanup event listener on component unmount
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, []);


    const carouselRef = useRef(null);
    const [currentSlide, setCurrentSlide] = useState(0);

    const imageArray = Object.values(estatePlan.images);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentSlide((prevSlide) => (prevSlide + 1) % imageArray.length);
        }, 10000); //Change slide every 10 seconds

        return () => clearInterval(interval);
    }, [imageArray.length]);

    const handleNextSlide = (e) => {
        e.stopPropagation();
        setCurrentSlide((prevSlide) => (prevSlide + 1) % imageArray.length);
    };

    const handlePrevSlide = (e) => {
        e.stopPropagation();
        setCurrentSlide((prevSlide) => (prevSlide - 1 + imageArray.length) % imageArray.length);
    };

    const handleSlideClick = () => {
        setCurrentSlide((prevSlide) => (prevSlide + 1) % imageArray.length);        
    }

    const description = estatePlan.description.join('\n');
    const descriptionLines = description.split('\n');
    const truncatedDescription = descriptionLines.length > 1 ? descriptionLines[0] + '...' : descriptionLines[0];
    const items = getItemsByIds(estatePlan.items);
    const shouldShowReadMoreItems = items.length > 4;
    const shouldShowReadMore = descriptionLines.length > 1; 

    const truncatedItems = shouldShowReadMoreItems ? items.slice(0, 4) : items;
    const remainingItems = shouldShowReadMoreItems ? items.slice(4) : [];

    return (
        <div className="packages">
            <canvas id="particleCanvas1" className="particle-canvas"></canvas>
            <div className="left">
                <div className="carouse" ref={carouselRef} onClick={handleSlideClick}>
                    <div className="carouse-wrapper" style={{ transform: `translateX(-${currentSlide * 100}%)` }}>
                        {imageArray.map((image, index) => (
                            <div key={index} className={`slid ${currentSlide === index ? 'active' : ''}`}>
                                <img src={image} alt={`Estate ${index}`} />
                            </div>
                        ))}
                    </div>
                    <button className="carouse-control prev" onClick={handlePrevSlide}>&lt;</button>
                    <button className="carouse-control next" onClick={handleNextSlide}>&gt;</button>
                </div>
            </div>
            <div className="right">
                <div className="product-text">
                    <h2>{estatePlan.name}</h2>
                    <h4 style={{ textAlign: 'left', fontWeight: 'bolder', fontSize: '1.5rem', }}>
                    ${estatePlan.amount.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                    </h4>
                    <div className="star-rating" >
                        <div className="star-rating">
                            <span>5.0 ★★★★★</span>
                        </div>
                    </div>
                    <span className='featureHead'>Items:</span>
                    <ul>
                        {truncatedItems.map((item, index) => (
                            <li key={item.id}
                                style={{ cursor: 'pointer', }}
                                onClick={() => handleItemClick(item)}>
                                {item.name}
                            </li>
                        ))}
                        {shouldShowReadMoreItems && (
                            <li
                                className="read-more"
                                onClick={() => setShowItemsDropdown(!showItemsDropdown)}
                                ref={dropdownRef}
                            >
                                {remainingItems.length} items remaining <span className="dropdown-symbol">&#9660;</span>
                            </li>
                        )}
                    </ul>
                    {showItemsDropdown && (
                        <ul className="remaining-items-dropdown" style={{ top: dropdownRef.current.offsetTop + 20, left: dropdownRef.current.offsetLeft }}>
                            {remainingItems.map((item) => (
                                <li key={item.id} onClick={() => handleItemClick(item)}>
                                    {item.name}
                                </li>
                            ))}
                        </ul>
                    )}
                    <p>
                        <span className='featureHead'>Description:</span>
                        <br />
                        {shouldShowReadMore ? truncatedDescription : description}
                    </p>
                    {shouldShowReadMore && (
                        <div>
                            <Link className="read-more" onClick={() => handleOpenPopup()}>
                                See Full Details...
                            </Link>
                        </div>
                    )}
                    <hr />
                    <div className="pricing">
                        <div>
                            <h5 className="customizeKit" onClick={handleAddToCart}>
                                Customize Kit
                            </h5>
                        </div>
                        <div>
                            <p className="buy-link" onClick={handleAddToCart}>
                                Add to Cart
                            </p>
                        </div>
                    </div>
                    


                    <GatewayPopup
                        show={showPopup}
                        handleClose={handleClosePopup}
                        content={
                            <div>
                                <span className='featureHead'>Items:</span>
                                <ul>
                                    {items.map(item => (
                                        <li key={item.id}>{item.name}</li>
                                    ))}
                                </ul>
                                <p>
                                    <span className='featureHead'>Description:</span>
                                    <br />
                                    <ol>
                                        {estatePlan.description.map((description, index) => (
                                            <li key={index}>{description}</li>
                                        ))}
                                    </ol>
                                </p>

                                {estatePlan.features && (
                                    <>
                                        <span className='featureHead'>Features:</span>
                                        <ul>
                                            {estatePlan.features.map((feature, index) => (
                                                <li key={index}>{feature}</li>
                                            ))}
                                        </ul>
                                    </>
                                )}

                                {estatePlan.benefits && (
                                    <>
                                        <span className='featureHead'>Benefits:</span>
                                        <ul>
                                            {estatePlan.benefits.map((benefit, index) => (
                                                <li key={index}>{benefit}</li>
                                            ))}
                                        </ul>
                                    </>
                                )}

                                {estatePlan.applications && (
                                    <>
                                        <span className='featureHead'>Applications:</span>
                                        <ul>
                                            {estatePlan.applications.map((application, index) => (
                                                <li key={index}>{application}</li>
                                            ))}
                                        </ul>
                                    </>
                                )}

                                        <div>
                                            <h3>Rating: 5.0 </h3>
                                            
                                        </div>
                                        
                                        <textarea
                                            rows="4"
                                            cols="50"
                                            placeholder="Add your comment here..."
                                            value={comment}
                                            onChange={handleCommentChange}
                                            
                                        ></textarea>
                                        <div className="buy">
                                            <button className='button-link' onClick={handleCommentSubmit}>Submit</button>
                                        </div>

                            </div>
                        }
                    />

                    {selectedItem && (
                        <ItemPopup
                            show={!!selectedItem}
                            handleClose={handleCloseItemPopup}
                            item={selectedItem}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

export default EstatePlan;
