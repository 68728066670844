import React from 'react';
import '../LinkedInFeed.css';

const LinkedInFeed = () => {
  return (
    <div className="linkedin-feed">
        <div>

            <iframe src='https://widgets.sociablekit.com/linkedin-page-posts/iframe/25442594' frameborder='0' width='90%' height='100%' title="Embedded post"></iframe>

        </div>

        <div>

            <iframe src="https://www.linkedin.com/embed/feed/update/urn:li:share:7222225492475559941" height="732" width="504" frameborder="0" allowfullscreen="" title="Embedded post"></iframe>

            <iframe src="https://www.linkedin.com/embed/feed/update/urn:li:share:7221153820226027520" height="774" width="504" frameborder="0" allowfullscreen="" title="Embedded post"></iframe>

            
        </div>

    </div>
  );
};

export default LinkedInFeed;