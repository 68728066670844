import { jwtDecode } from 'jwt-decode';
import { Navigate } from 'react-router-dom';


function AdminProtectedRoute ({ children }) {
    const token = localStorage.getItem('authToken');
    if(!token) {
        return <Navigate to="/admin-login" />;

    }

    const decodedToken = jwtDecode(token);
    if (decodedToken.role !== 'admin') {
        return <Navigate to="/user-login" />;
    }

    return children;
}

export default AdminProtectedRoute;