import React, { useState } from 'react';
import ContactChatBox from './ContactChatBox';
import ChatBox1 from './ChatBox1';

const MainChat1 = () => {
    const [ticketId, setTicketId] = useState(null);

    const handleTicketIdUpdate = (id) => {
        setTicketId(id);
    };

    return (
        <div>
            {ticketId ? (
                <ChatBox1 ticketId={ticketId} />
            ) : (
                <ContactChatBox onTicketIdUpdate={handleTicketIdUpdate} />
            )}
        </div>
    );
};

export default MainChat1;
