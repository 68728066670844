// axiosInstance.js
import axios from 'axios';

// Create an instance of Axios
const axiosInstance = axios.create({
    baseURL: 'http://127.0.0.1:8000/api/',
    headers: {
        'Content-Type': 'application/json',
    },
});

// Get the token from sessionStorage and set it if available
const token = sessionStorage.getItem('token');
if (token) {
    axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
}

// Interceptor to handle token expiry and redirect to login
axiosInstance.interceptors.response.use(
    response => response,
    error => {
        if (error.response && error.response.status === 401) {
            sessionStorage.removeItem('token');
            window.location.href = '/admin-login1'; // Redirect to login page
        }
        return Promise.reject(error);
    }
);

export default axiosInstance;
