import React, { useEffect, useState } from 'react';
import { useLocation, Link } from 'react-router-dom';
import ProductsData, { relatedProductsData, EstatePackageData} from '../components/ProductsData'; 
import ContactDoctor from './ContactDoctor';
import FirstNav2 from './FirstNav2';
import Footer from './Footer';

const SearchResults = () => {
    const location = useLocation();
    const query = new URLSearchParams(location.search).get('q');
    const [results, setResults] = useState([]);

    useEffect(() => {
        if (query) {
            // Perform the search by filtering through all datasets
            const productResults = ProductsData.filter(product =>
                product.name.toLowerCase().includes(query.toLowerCase())
            );

            const relatedProductResults = relatedProductsData.filter(product =>
                product.name.toLowerCase().includes(query.toLowerCase())
            );

            const estatePlanResults = EstatePackageData.filter(plan =>
                plan.name.toLowerCase().includes(query.toLowerCase())
            );

            setResults([...productResults, ...relatedProductResults, ...estatePlanResults]);
        }
    }, [query]);

    const renderResult = (result) => {
        if (ProductsData.includes(result)) {
            return <Link style={{color: 'black',}}  to="/products">{result.name}</Link>;
        } else if (EstatePackageData.includes(result)) {
            return <Link style={{color: 'black',}} to="/estate-plans">{result.name}</Link>;
        } else if (relatedProductsData.includes(result)) {
            return <Link style={{color: 'black',}} to={`/items/${result.id}`}>{result.name}</Link>;
        } else {
            return null;
        }
    };

    return (
        <div>
            <FirstNav2 />
            <div className="search-results">
                <h2>Search Results for "{query}"</h2>
                <div>
                    {results.length > 0 ? (
                        results.map(result => (
                            <div  key={result.id || result.name}>
                                <p>{renderResult(result)}</p>
                            </div>
                        ))
                    ) : (
                        <p>No results found</p>
                    )}
                </div>
            </div>
            <hr />
            <ContactDoctor />
            <Footer />
        </div>

    );
};

export default SearchResults;
