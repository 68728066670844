import React, { useEffect } from 'react';

const FacebookFeed = () => {
  useEffect(() => {
    const loadFacebookScript = () => {
      if (!document.getElementById('facebook-jssdk')) {
        const script = document.createElement('script');
        script.id = 'facebook-jssdk';
        script.src = 'https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v11.0';
        document.body.appendChild(script);
      } else {
        window.FB && window.FB.XFBML.parse();
      }
    };
    loadFacebookScript();
  }, []);

  return (
    <div className="facebook-feed" >
      <div
        className="fb-page"
        data-href="https://m.facebook.com/p/Mainlogix-Technology-Limited-100067015779535/"
        data-tabs="timeline"
        data-width="500"
        data-height="700"
        data-small-header="false"
        data-adapt-container-width="true"
        data-hide-cover="false"
        data-show-facepile="true"
      >
        <blockquote
          cite="https://m.facebook.com/p/Mainlogix-Technology-Limited-100067015779535/"
          className="fb-xfbml-parse-ignore"
        >
          
        </blockquote>
      </div>
    </div>
  );
};

export default FacebookFeed;