import React, { useState, useEffect} from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import UserLogin from './pages/UserLogin';
import AdminLogin from './pages/AdminLogin1';
import UserDashboard from './pages/UserDashboard';
import ProtectedRoute from './components/ProtectedRoute';
import AdminProtectedRoute from './components/AdminProtectedRoute';
import Welcome from './pages/Welcome';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './BookACall.css';

import NotFound from './pages/NotFound';
import './styles.css';
import './productStyle.css';
import './StarRating.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import Products from './pages/Products';
import Blog from './pages/Blog';
import Integration from './pages/Integration';
import TawkTo from './components/TwakTo';
import GeolocationRedirect from './components/GeolocationRedirect';
import PaymentPage from './pages/PaymentPage';
import { CartProvider } from './contexts/CartContext';
import CartPage from './pages/CartPage';
import CheckoutPage from './pages/CheckoutPage';
import ItemDetails from './pages/ItemDetails';
import ExchangeRateConverter from './pages/ExchangeRate';
import CustomizeKitPage from './pages/CustomizeKitPage';
import EstatePlansPage from './pages/EstatePlansPage';
import CustomizeEstateKitPage from './pages/CustomizeEstateKitPage';
import SearchBar from './components/SearchResults';
import BookACall from './components/BookACall';
import ContactChatBox from './pages/ContactChatBox';
import MainChat1 from './pages/MainChat1';
import AdminLogin1 from './pages/AdminLogin1';
import AdminDashboard1 from './pages/AdminDashboard1';
import AdminSignup1 from './pages/AdminSignup1';
import AdminChatBox1 from './pages/AdminChatBox1';
import WelcomePopup from './pages/WelcomePopUp';


function App() {

  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      const hasVisited = localStorage.getItem('hasVisited');
      if (!hasVisited) {
        setShowPopup(true);
        localStorage.setItem('hasVisited', 'true');
      }
    }, 5000); 
    return () => clearTimeout(timer);
  }, []);

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  return (
    <div>
    {showPopup && <WelcomePopup onClose={handleClosePopup} />}
    <CartProvider>
    <Router>
      <TawkTo />
      <GeolocationRedirect />
      <ToastContainer 
        autoClose={1000}
        hideProgressBar={true}
      />
      <BookACall />
      
      <Routes>

        
        
        <Route path='/'element={<Welcome />} />
        <Route path="/welcome" element={<Welcome/>} />
        <Route path="/user-login" element={<UserLogin />} />
        <Route path="/admin-login" element={<AdminLogin />} />
        <Route path="/products" element={<Products />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/integration" element={<Integration />} />
        <Route path="/customize/:id" element={<CustomizeKitPage />} />
        <Route path="/customize-estate/:id" element={<CustomizeEstateKitPage />} />
        <Route path="/checkout" element={<CheckoutPage />} />
        <Route path="/buy-now/:id" element={<PaymentPage />} />
        <Route path="/cart" element={<CartPage />} />
        <Route path="/items/:id" element={<ItemDetails />} />
        <Route path="/estate-plans" element={<EstatePlansPage />} />
        <Route path="/exchange-rate" element={<ExchangeRateConverter />} />
        
        {/* 404/undefined page route */}
        <Route path='*' element={<NotFound />} />
        {/* End of 404 undefined page route */}

        <Route path="/search" element={<SearchBar />} />

        <Route path='/chat-page1' element={<ContactChatBox />} />
        <Route path='/user-chat1' element={<MainChat1 />} />
        <Route path='/admin-chat1' element={<AdminChatBox1 />} />

        <Route path='/admin-dashboard1' element={<AdminDashboard1 />} />
        <Route path='/admin-login1' element={<AdminLogin1 />} />
        <Route path='/admin-signup1' element={<AdminSignup1 />} />

        {/* <Route path="/start-chat" component={TicketForm} />
        <Route path="/chat/:ticketId" component={UserChat} />
        <Route path="/admin" component={ChatQueue} /> */}


        {/* protected admin dashboard */}
        <Route
        path="/admin-dashboard"
        element={
          <AdminProtectedRoute>
      
            {/* <AdminChatBox /> */}
          </AdminProtectedRoute>
        }
        />
        {/* protected user route */}
        <Route
        path="/user-dashboard"
        element={
          <ProtectedRoute>
            <UserDashboard />
          </ProtectedRoute>
        }
        />
        
      </Routes>
    </Router>
    </CartProvider>
    </div>
  );
}

export default App;
