import React from 'react';
import '../InstagramFeed.css';

const InstagramFeed = () => {
  return (
    <div className="instagram-feed-container">
      <iframe 
        src="//lightwidget.com/widgets/e2532a0294125c53aeb927be2e79147d.html" 
        allowTransparency="true" 
        className="lightwidget-widget"
        title="Instagram Feeds"
      >
      </iframe>

   

    </div>
  );
};

export default InstagramFeed;
