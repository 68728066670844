import React, { useState } from 'react';
import axiosInstance from '../components/axiosInstance';
import { useNavigate, Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function AdminLogin1() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passcode, setPasscode] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axiosInstance.post('admin/login/', { email, password, passcode });
      if (response.status === 200) {
        const token = response.data.token;
        sessionStorage.setItem('token', token);
        console.log(response.data.token);
        axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        toast.success(response.data.message);
        navigate('/admin-dashboard1');
      }
    } catch (error) {
      if (error.response && error.response.data) {
        toast.error(error.response.data.error);
      } else {
        toast.error('An error occurred. Please try again.');
      }
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div>
      <ToastContainer />
      <section className="vh-100" style={{ backgroundColor: "#ffffff" }}>
        <div className="container py-5 h-100">
          <div className="row d-flex justify-content-center align-items-center h-100">
            <div className="col col-xl-20">
              <div className="card" style={{ borderRadius: "1rem" }}>
                <div className="row g-0">
                  <div className="col-md-6 col-lg-5 d-none d-md-block">
                    <img
                      src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-login-form/img1.webp"
                      alt="login form"
                      className="img-fluid"
                      style={{ borderRadius: "1rem 0 0 1rem" }}
                    />
                  </div>
                  <div className="col-md-6 col-lg-7 d-flex align-items-center">
                    <div className="card-body p-4 p-lg-5 text-black">
                      <form onSubmit={handleSubmit} className='form'>
                        <div className="d-flex align-items-center mb-3 pb-1">
                          <span className="h2 fw-bold mb-0">
                            Welcome Back <b>Grandeursmart Admin👋</b>
                          </span>
                        </div>
                        <h2 className="fw-normal mb-3 pb-3" style={{ letterSpacing: 1 }}>
                          Login
                        </h2>
                        <div className="form-outline mb-4">
                          <input
                            type="email"
                            name="email"
                            placeholder="Email"
                            onChange={(e) => setEmail(e.target.value)}
                            id="form2Example17"
                            className="form-control form-control-lg"
                            required
                          />
                        </div>
                        <div className="form-outline mb-4">
                          <input
                            type={showPassword ? "text" : "password"}
                            name="password"
                            placeholder="Password"
                            onChange={(e) => setPassword(e.target.value)}
                            id="form2Example17"
                            className="form-control form-control-lg"
                            required
                          />
                          <button
                            type="button"
                            style={{
                              backgroundColor: "white",
                              color: "#A97D5D",
                              fontSize: "small",
                              fontWeight: "bold"
                            }}
                            onClick={togglePasswordVisibility}
                          >
                            {showPassword ? 'Hide' : 'Show'}
                          </button>
                        </div>
                        <div className="form-outline mb-4">
                          <input
                            type="text"
                            name="passcode"
                            placeholder="Passcode"
                            onChange={(e) => setPasscode(e.target.value)}
                            id="form2Example17"
                            className="form-control form-control-lg"
                            required
                          />
                        </div>
                        <div className="pt-1 mb-4">
                          <button
                            className="btn btn-lg btn-block"
                            type="submit"
                            style={{ backgroundColor: "#5C755E", color: 'white' }}
                          >
                            Login
                          </button>
                        </div>
                        <a className="small text-muted" href="#!">
                          Forgot password?
                        </a>
                        <p className="mb-5 pb-lg-2" style={{ color: "#A97D5D" }}>
                          Don't have an account?{" "}
                          <Link to="/admin-signup1" style={{ color: "#A97D5D" }}>
                            Signup Now
                          </Link>
                        </p>
                        <a href="#!" className="small text-muted">
                          Terms of use.
                        </a>
                        <a href="#!" className="small text-muted">
                          Privacy policy
                        </a>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default AdminLogin1;
