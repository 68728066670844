// CartPage.js
import React, { useEffect } from 'react';
import { useCart } from '../contexts/CartContext';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import RelatedProduct from '../components/RelatedProducts';
import '../CartPage.css';
import FirstNav2 from '../components/FirstNav2';

const CartPage = () => {
    const { cartItems, removeFromCart, increaseQuantity, decreaseQuantity } = useCart();
    const navigate = useNavigate();

    const handleCheckout = () => {
        navigate('/checkout');
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);


    const showAlert = (item, action) => {
        const packageInfo = item.selectedPackage ? ` (${item.selectedPackage} Package)` : '';
        const message = action === 'remove' ? `${item.name}${packageInfo} has been removed from cart!` : `${item.name}${packageInfo} quantity updated!`;
        toast.success(message);
    };

    const handleRemoveFromCart = (item) => {
        removeFromCart(item.id, item.selectedPackage);
        showAlert(item, 'remove');
    };

    const handleIncreaseQuantity = (item) => {
        increaseQuantity(item.id, item.selectedPackage);
        showAlert(item, 'increase');
    };

    const handleDecreaseQuantity = (item) => {
        if (item.quantity > 1) {
            decreaseQuantity(item.id, item.selectedPackage);
            showAlert(item, 'decrease');
        }
    };

    // Function to get item image
    const getItemImage = (item) => {
        if (item.selectedPackage && item[item.selectedPackage]?.images) {
            return item[item.selectedPackage].images[0];
        } else if (item.image) {
            return item.image[0];
        } else if (item.images) {
            return item.images[0];
        } else {
            return 'default-image.png';
        }
    };

    // Function to calculate item amount
    const calculateItemAmount = (item) => {
        let amount = 0;
        if (item.selectedPackage && item[item.selectedPackage]?.amount) {
            amount = item[item.selectedPackage].amount * item.quantity;
        } else if (!isNaN(item.amount)) {
            amount = item.amount * item.quantity;
        }
        return amount.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
    };

    return (
        <div>
            <FirstNav2 />
            <div className="cart-container">
                <h2 className="cart-title">Your Cart</h2>
                {cartItems.length === 0 ? (
                    <p className="cart-empty">Your cart is empty.</p>
                ) : (
                    <>
                        <ul className="cart-list">
                            {cartItems.map((item) => (
                                <li key={`${item.id}-${item.selectedPackage || 'none'}`} className="cart-item">
                                    <img src={getItemImage(item)} alt={item.name} className="cart-item-image" />
                                    <div className="cart-item-details">
                                        <p className="cart-item-name">{item.name}</p>
                                        {item.selectedPackage && <p className="cart-item-package">{item.selectedPackage} Package</p>}
                                        <p className="cart-item-price">${calculateItemAmount(item)}</p>
                                        <div className="quantity-controls">
                                            <button className="quantity-button" onClick={() => handleDecreaseQuantity(item)}>-</button>
                                            <span className="quantity">{item.quantity}</span>
                                            <button className="quantity-button" onClick={() => handleIncreaseQuantity(item)}>+</button>
                                        </div>
                                        <button className="cart-item-remove" onClick={() => handleRemoveFromCart(item)}>Remove</button>
                                    </div>
                                </li>
                            ))}
                        </ul>
                        <div className="cart-actions">
                            <Link to="/products" className="cart-continue-shopping">Continue Shopping</Link>
                            <button className="cart-checkout-button" onClick={handleCheckout}>Proceed to Checkout</button>
                        </div>
                    </>
                )}
            </div>
            <RelatedProduct />
        </div>
    );
};

export default CartPage;
