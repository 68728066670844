import React from "react";
import { Link } from "react-router-dom";
import 'bootstrap/dist/js/bootstrap.bundle';
import 'bootstrap/dist/js/bootstrap';

// //import $ from 'jquery';   
// import 'owl.carousel/dist/assets/owl.carousel.css';
// import 'owl.carousel';
// import 'owl.carousel/dist/assets/owl.theme.default.css';

//import  OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css'
import 'owl.carousel/dist/assets/owl.theme.default.css';
import '../owl.css';


function Blog() {
  

    return (
        <div>
            

            {/* Nav Start */}

            <nav className="navbar navbar-expand-lg navbar-light bg-light">
              <div className="container-fluid">
                <Link className="navbar-brand" to="/"><img src="assets/img/logo.png" alt="" /></Link>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
                  aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                  <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                        <li className="nav-item">
                            <Link className="nav-link active" aria-current="page" to="/">Home</Link>
                        </li>

                        <li className="nav-item">
                            <Link className="nav-link" to="/products">Product</Link>
                        </li>                        

                        <li className="nav-item">
                            <Link className="nav-link" to="/integration">Integration</Link>
                        </li>
                        
                        

                        <li className="nav-item dropdown">
                            <Link className="nav-link dropdown-toggle" to="/" id="navbarDropdown" role="button"
                                data-bs-toggle="dropdown" aria-expanded="false">
                                Dropdown
                            </Link>
                            <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                                <li><Link className="dropdown-item" to="/">Action</Link></li>
                                <li><Link className="dropdown-item" to="/">Another action</Link></li>
                                <li>
                                    <hr className="dropdown-divider" />
                                </li>
                                <li><Link className="dropdown-item" to="/">Something else here</Link></li>
                            </ul>
                        </li>


                    </ul>
                    <form className="d-flex" role="search">
                        <input className="form-control me-2" type="search" placeholder="Search" aria-label="Search" />
                        <button className="btn btn-outline-success" type="submit">Search</button>
                    </form>
                </div>
              </div>
            </nav>

            {/* End of Nav Bar */}
        

            

  <hr />

  {/* Start Contact Doctor Area */}
  <div className="contact-doctor-area ptb-100">
    <div className="container">
      <div className="row align-items-center">
        <div className="col-lg-6">
          <div className="contact-doctor-contain" data-aos="fade-right" data-aos-duration="1200" data-aos-delay="200">
            <div className="contact-title">
              <span>Contact Us</span>
              <h2>
                Connect with Grandeur Smart
              </h2>
              <p> 315 Herbert Macaulay Way, Yaba, <br />Lagos 100001, Lagos</p>

            </div>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="contact-doctor-form" data-aos="fade-left" data-aos-duration="1200" data-aos-delay="200">
            <form>
              <div className="row">
                <div className="col-lg-6 col-md-6">
                  <div className="form-group">
                    <input type="text" className="form-control" placeholder="Name*" />
                  </div>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className="form-group">
                    <input type="email" className="form-control" placeholder="Email*" />
                  </div>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className="form-group">
                    <input type="text" className="form-control" placeholder="Phone*" />
                  </div>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className="form-group">
                    <input type="text" className="form-control" placeholder="Subject*" />
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="form-group">
                    <textarea className="form-control" rows="3" placeholder="Message*"></textarea>
                  </div>
                </div>
                <div className="col-lg-12">
                  <button type="submit" className="default-btn active">
                    Send Message
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
  {/* End Contact Doctor Area */}


  <hr />

  {/* Start Footer Area */}
  <div className="footer-area">
    <div className="container">
      <div className="footer pt-100 pb-70">
        <div className="row">
          <div className="col-lg-4 col-sm-6">
            <div className="logo-area" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="200">
              <Link to="index.html"><img src="assets/img/logo.png" alt=" " /></Link>
              <p>
                Subscribe to Grandeur Smart newsletter for the latest health tips, updates, and exclusive offers. Enter
                your
                email below to join our community and prioritize your well-being."
              </p>
              <form className="newsletter-form" data-toggle="validator">
                <input type="email" className="form-control" placeholder="Your Email" name="EMAIL" required
                  autocomplete="off" />

                <button className="default-btn active" type="submit">
                  Subscribe
                </button>

                <div id="validator-newsletter" className="form-result"></div>
              </form>
            </div>
          </div>

          <div className="col-lg-2 col-sm-6">
            <div className="quick-link" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="400">
              <h3>Quick Link</h3>
              <ul>

                <li>
                  <Link to="about-us.html">
                    <i className="ri-add-fill"></i>
                    <p>Blog</p>
                  </Link>
                </li>
                <li>
                  <Link to="privacy-policy.html">
                    <i className="ri-add-fill"></i>
                    <p>Integration</p>
                  </Link>
                </li>
                <li>
                  <Link to="terms-conditions.html">
                    <i className="ri-add-fill"></i>
                    <p>Product</p>
                  </Link>
                </li>

              </ul>
            </div>
          </div>

          <div className="col-lg-3 col-sm-6">
            <div className="helpful-link" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="600">
              <h3>Address</h3>
              <ul>
                <li>
                  <p>315 Herbert Macaulay Way, Yaba, </p>
                  <p>Lagos 100001, Lagos</p>
                </li>
                <li>
                  <span>Phone:</span><Link to="tel:+234-813-997-2473">( +234 813 997 2473)</Link>
                </li>
                <li>
                  <span>Email:</span><Link to="mailto:info@Grandeursmart.com">info@Grandeursmart.com</Link>
                </li>
              </ul>
            </div>
          </div>

          <div className="col-lg-1 col-sm-6">
            <div className="custom-div left" style={{flexDirection: "row", }}>
              <img src="assets/img/google_play.png" alt="" height={50} width={160} />
              <img src="assets/img/apple_store.png" alt="" height={40} width={150} />
            </div>
          </div>
        </div>
      </div>
      <div className="copyright">
        <div className="row">
          <div className="col-lg-6">
            <div className="copy">
              <p>
                © Copywrite 2024 Grandeur Smart
                <Link to="yyy" target="_blank">Grandeur Smart</Link>
              </p>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
  {/* End Footer Area */}


        </div>

  
    );
}

export default Blog;