import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useCart } from '../contexts/CartContext'; // Import the CartContext
import SearchBar from './SearchBar'; // Import the SearchBar component
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

function FirstNav2() {
    const [isOpen, setIsOpen] = useState(false);
    const { cartItems } = useCart(); // Get cart items from the context
    const itemCount = cartItems.length; // Count the items in the cart

    const toggleNavbar = () => {
        setIsOpen(!isOpen);
    };

    // Hardcoded image paths for testing
    const logoPath = '/assets/img/logo.png';
    const cartIconPath = '/assets/img/AppImage/icons8-cart-80.png';

    return (
        <div>
            {/* Nav Start */}
            <nav className="navbar navbar-expand-lg navbar-light bg-light">
                <div className="container-fluid">
                    <Link className="navbar-brand" to="/">
                        <img src={logoPath} alt="logo" />
                    </Link>
                    <button
                        className="navbar-toggler"
                        type="button"
                        onClick={toggleNavbar}
                        aria-controls="navbarSupportedContent"
                        aria-expanded={isOpen ? "true" : "false"}
                        aria-label="Toggle navigation"
                    >
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className={`collapse navbar-collapse ${isOpen ? "show" : ""}`} id="navbarSupportedContent">
                        
                        <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                            <li className="nav-item">
                                <Link className="nav-link active" aria-current="page" to="/">Home</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="/products">Product</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="/estate-plans">Real Estate</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="/">Integration</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="/">Blog</Link>
                            </li>
                        </ul>
                        <div className="buttons" style={{marginBottom: '0px', marginRight: '0px', paddingBottom: '0px'}}>
                            <SearchBar /> {/* Replace Sign Up button with SearchBar */}
                        </div>
                    </div>
                    {itemCount > 0 && (
                        <div className="cart-icon">
                            <Link to="/cart">
                                <img src={cartIconPath} alt="Cart" />
                                <span className="item-count">{itemCount}</span>
                            </Link>
                        </div>
                    )}
                </div>
            </nav>
            {/* End of Nav Bar */}
        </div>
    );
}

export default FirstNav2;
