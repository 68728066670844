import React from 'react';
import { Carousel, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import ProductsData from '../components/ProductsData';
import '../ProductKitCarousel.css';
import { useCart } from '../contexts/CartContext';

const ProductKitCarousel = () => {
    const { addToCart } = useCart();
    const navigate = useNavigate(); 

    const handleAddToCart = (productKit, selectedPackage) => {
        const productToCart = {
            ...productKit,
            selectedPackage: selectedPackage,
            amount: productKit[selectedPackage].amount,
            image: productKit[selectedPackage].images[0],
            quantity: 1,
        };
        addToCart(productToCart);
        showAlert(productToCart);
        navigate('/cart');
    };

    function showAlert(product) {
        toast.success(`${product.name} (${product.selectedPackage} Package) has been added to your cart!`);
    };

    return (
        <div className="carousel-video-container">
            <video 
                autoPlay 
                muted 
                loop
                playsInline 
                className="background-video">
                <source src="assets/Product-Kit-Videos/product-estate.mp4" type="video/mp4" />
                Your browser does not support the video tag.
            </video>
            <Carousel fade className="carousel-content">
                {ProductsData.map((productKit) => {
                    const availablePackages = ['Basic', 'Advance', 'Premium', 'Ultimate'].filter(selectedPackage => productKit[selectedPackage]);

                    return (
                        <Carousel.Item key={productKit.id}>
                            <div className="carousel-item-contnt">
                                <div className="carousel-caption-container">
                                    <Carousel.Caption className="carousel-captio">
                                        <h2 style={{color: '#fff',}}>{productKit.name}</h2>
                                    </Carousel.Caption>
                                </div>
                                <div className="carousel-buttos">
                                    {availablePackages.map((selectedPackage, index) => (
                                        <Button
                                            key={selectedPackage}
                                            variant="secondary"
                                            className="carousel-button customize-button"
                                            style={{ animationDelay: `${index * 0.25}s` }}
                                        
                                        >
                                            {selectedPackage}
                                        </Button>
                                        // onClick={() => handleAddToCart(productKit, selectedPackage)}
                                    ))}
                                </div>

                            </div>
                        </Carousel.Item>
                    );
                })}
            </Carousel>
        </div>
    );
};

export default ProductKitCarousel;
