import React, {useEffect, useState} from 'react';
import { useParams, useNavigate } from 'react-router-dom'; // Use useNavigate
import { relatedProductsData } from '../components/ProductsData';
import { useCart } from '../contexts/CartContext';
import { toast } from 'react-toastify';
import '../ItemDetails.css';
import FirstNav3 from '../components/FirstNav3';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

const ItemDetails = () => {
    const { id } = useParams();
    const navigate = useNavigate(); // Use useNavigate
    const item = relatedProductsData.find(product => product.id === parseInt(id));
    const { addToCart, increaseQuantity, decreaseQuantity, getCartItem } = useCart();

    const showAlert = (item, action) => {
        const message = action === 'remove' ? `${item.name} has been removed from cart!` : `${item.name} quantity updated!`;
        toast.success(message);
    };

    const handleButtonClick = (item) => {
        addToCart(item);
        showAlert(item);
    };

    const handleIncreaseQuantity = (item) => {
        increaseQuantity(item.id);
        showAlert(item, 'increase');
    };

    const handleDecreaseQuantity = (item) => {
        if (cartItem.quantity > 1) {
            decreaseQuantity(item.id);
            showAlert(item, 'decrease');
        }
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const [scrollPosition, setScrollPosition] = useState(0);

useEffect(() => {
    const handleScroll = () => {
        const position = window.scrollY;
        setScrollPosition(position);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
        window.removeEventListener('scroll', handleScroll);
    };
}, []);

const buttonStyle = {
    backgroundColor: scrollPosition > 100 ? 'rgba(169,125,93, 0.7)' : 'rgba(0, 0, 0, 0.1)',
    color: scrollPosition > 100 ? '#ffffff' : 'ffffff',
};

    if (!item) {
        return <p>Item not found</p>;
    }

    

    const cartItem = getCartItem(item.id);

    return (
        <div>
            <FirstNav3 />
            <div className="item-details-container">
                
                <div className="item-details">
                <button className="back-button" style={buttonStyle} onClick={() => navigate(-1)}>
                <span> &lsaquo; </span> Products
                    </button>
                
                    <div className="item-image-container">
                    
                        <Carousel
                            showThumbs={true}
                            showStatus={false}
                            infiniteLoop
                            useKeyboardArrows
                            autoPlay
                            interval={3000}
                            stopOnHover
                        >
                            {item.image.map((img, index) => (
                                <div key={index}>
                                    <img src={`${process.env.PUBLIC_URL}/${img}`} alt={`${item.name}-${index}`} className="item-image" />
                                </div>
                            ))}
                        </Carousel>
                    </div>
                    <div className="item-info">
                        <h2 className="item-name">{item.name}</h2>
                        <p className="item-amount">${item.amount.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
                        <button className="add-to-cart-button" onClick={() => handleButtonClick(item)}>
                            Add to Cart
                        </button>
                        {cartItem && (
                            <div className="quantity-controls">
                                <button className="quantity-button" onClick={() => handleDecreaseQuantity(item)}>-</button>
                                <span className="quantity">{cartItem.quantity}</span>
                                <button className="quantity-button" onClick={() => handleIncreaseQuantity(item)}>+</button>
                            </div>
                        )}
                        <div className="item-description">
                            {item.description && (
                                <>
                                    <h3 className="item-name" style={{ marginTop: '5px', color: '#A97D5D' }}>Description</h3>
                                    <p>{item.description}</p>
                                </>
                            )}

                            {item.features && item.features.length > 0 && (
                                <>
                                    <h3 className="item-name" style={{ marginTop: '20px', color: '#A97D5D' }}>Features</h3>
                                    <ol className="item-features">
                                        {item.features.map((feature, index) => (
                                            <li key={index}>{feature}</li>
                                        ))}
                                    </ol>
                                </>
                            )}

                            {item.benefits && item.benefits.length > 0 && (
                                <>
                                    <h3 className="item-name" style={{ marginTop: '20px', color: '#A97D5D' }}>Benefits</h3>
                                    <ol className="item-benefits">
                                        {item.benefits.map((benefit, index) => (
                                            <li key={index}>{benefit}</li>
                                        ))}
                                    </ol>
                                </>
                            )}

                            {item.applications && item.applications.length > 0 && (
                                <>
                                    <h3 className="item-name" style={{ marginTop: '20px', color: '#A97D5D' }}>Applications</h3>
                                    <ol className="item-applications">
                                        {item.applications.map((application, index) => (
                                            <li key={index}>{application}</li>
                                        ))}
                                    </ol>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ItemDetails;
