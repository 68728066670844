import React, { useState, useEffect } from 'react';
import { useCart } from '../contexts/CartContext';
import { relatedProductsData } from './ProductsData.js'; // Import your data
import '../RelatedProducts.css'; // Import CSS for styling
import { Link } from "react-router-dom";
import { toast } from 'react-toastify';


const RelatedProducts = () => {
    const [currentPage, setCurrentPage] = useState(0);
    const [productsPerPage, setProductsPerPage] = useState(12); // Default to desktop view
    const { addToCart } = useCart();

    useEffect(() => {
        const updateProductsPerPage = () => {
            const width = window.innerWidth;
            if (width >= 1200) {
                setProductsPerPage(12); // 2 rows * 6 columns
            } else if (width >= 768) {
                setProductsPerPage(8); // 2 rows * 4 columns
            } else {
                setProductsPerPage(4); // 2 rows * 2 columns
            }
        };

        window.addEventListener('resize', updateProductsPerPage);
        updateProductsPerPage(); // Initial check

        return () => window.removeEventListener('resize', updateProductsPerPage);
    }, []);

    function showAlert(product) {
        // alert(`${product.name} has been added to your cart!`);
        toast.success(`${product.name} has been added to your cart!`); // Use 'success' type for positive notifications
      };

    function handleButtonClick(product) {
    addToCart(product);
    showAlert(product);
    }

    const startIndex = currentPage * productsPerPage;
    const endIndex = startIndex + productsPerPage;
    const currentProducts = relatedProductsData.slice(startIndex, endIndex);

    const handleNext = () => {
        if (endIndex < relatedProductsData.length) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePrev = () => {
        if (startIndex > 0) {
            setCurrentPage(currentPage - 1);
        }
    };

    return (
        <div className="related-products">
            <h2>Related Products</h2>
            <div className="product-grid">
                {currentProducts.map((product) => (
                    <div className="product-card" key={product.id}>
                        <Link to={`/items/${product.id}`}>
                            <div className="img">
                                <img src={product.image[0]} alt={product.name} />
                            </div>
                        </Link>
                        <div>
                            <h2 className="related-product-name">{product.name}</h2>
                            <p className='product-content'>
                                {product.description[0].substring(0, 180)}...
                            </p>
                            <div className="price-buy">
                                <div className="buy">
                                    <button 
                                    className='add-to-cart' onClick={() => handleButtonClick(product)}
                                    >Add to Cart</button>
                                </div>
                                <p>${product.amount}</p>
                                
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            <div className="navigation-buttons">
                <button onClick={handlePrev} disabled={startIndex === 0}>Prev</button>
                <button onClick={handleNext} disabled={endIndex >= relatedProductsData.length}>Next</button>
            </div>
        </div>
    );
};

export default RelatedProducts;
