import React from "react";
//import { Link } from "react-router-dom";
//import 'bootstrap/dist/js/bootstrap.bundle';
// //import $ from 'jquery';   
// import 'owl.carousel/dist/assets/owl.carousel.css';
// import 'owl.carousel';
// import 'owl.carousel/dist/assets/owl.theme.default.css';

import  OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css'
import 'owl.carousel/dist/assets/owl.theme.default.css';
import '../owl.css';
import FirstNav from "../components/FirstNav";


function Integration() {
  

    return (
        <div>

          <FirstNav />
            

            
            {/* style={{
                  backgroundImage: 'url("/assets/img/gateway.png")',
                  backgroundSize: 'cover', 
                  backgroundPosition: 'center'
                }} */}

            <div>  
           
            <div className='start' >            
            <OwlCarousel items={1}
            autoplay={true}  
              className="owl-theme"  
              loop={true}
              autoplayTimeout={3000}
              dots={true}
              nav={false} 
              navText={["<", ">"]}
              margin={8} >  
                              
                  <div className="start0"  >
                    <div className="slider">
                        <h2>GrandeurSmart Home Automation</h2>
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Mollitia provident eaque ab, vitae
                          temporibus
                          veritatis unde distinctio molestiae necessitatibus voluptas optio corrupti quis qui maxime laborum
                          fuga
                          nemo eius rerum?</p>
                    </div>
                  </div>

                  <div  className="start1">
                    <div className="slider">
                        <h2>GrandeurSmart H Automation</h2>
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Mollitia provident eaque ab, vitae
                          temporibus
                          veritatis unde distinctio molestiae necessitatibus voluptas optio corrupti quis qui maxime laborum
                          fuga
                          nemo eius rerum?</p>
                    </div>
                  </div>

                  <div className="start2">
                    <div className="slider">
                        <h2>GrandeurSmart Ho Automation</h2>
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Mollitia provident eaque ab, vitae
                          temporibus
                          veritatis unde distinctio molestiae necessitatibus voluptas optio corrupti quis qui maxime laborum
                          fuga
                          nemo eius rerum?</p>
                    </div>
                  </div>

                  <div className="start3">
                    <div className="slider">
                        <h2>GrandeurSmart Hom Automation</h2>
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Mollitia provident eaque ab, vitae
                          temporibus
                          veritatis unde distinctio molestiae necessitatibus voluptas optio corrupti quis qui maxime laborum
                          fuga
                          nemo eius rerum?</p>
                    </div>
                  </div>

                  <div  className="start4">
                    <div className="slider">
                        <h2>GrandeurSmart Home Automation</h2>
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Mollitia provident eaque ab, vitae
                          temporibus
                          veritatis unde distinctio molestiae necessitatibus voluptas optio corrupti quis qui maxime laborum
                          fuga
                          nemo eius rerum?</p>
                    </div>
                  </div>
              
          </OwlCarousel>  
          </div>  
  
      </div>  



            

            <div className="gateway-section">
              <canvas id="gatewayCanvas"></canvas>
              <h5>Our Gateway</h5>

              <div className="Image-text">
                <div className="image">
                  <img src="assets/img/grdnr_prdt_1.png" width="250" height="250" alt="" />
                </div>

                <div className="text">
                  <h3>GrandeurSmart Gateway</h3>
                  <p> GrandeurSmart Gateway is the pinnacle of smart home control, offering an array of features to enhance your
                    living experience:</p>

                  <h3> Key Features:</h3>

                  <p> Backup Battery: Ensure uninterrupted functionality during power outages.</p>
                  <p> Alternative Internet with SIM Card Slot: Stay connected with a reliable alternative internet source.</p>
                  <p> Hotspot Sharing: Extend connectivity by sharing internet access with your devices.</p>
                  <p> Artificial Intelligence Integration: Intelligent decision-making for a dynamic smart home.</p>
                  <p> SMART Voice Personal Assistant - Mainlogix: Effortless control using customizable wake words. E.g Hey Joy
                    turn
                    On the light</p>
                  <h3>Plus:</h3>

                  <p> Interoperability: Seamlessly integrate over 100,000 devices from various manufacturers. GrandeurSmart
                    Gateway
                    ensures compatibility, making it a versatile and future-proof solution for your smart home needs.
                    Elevate your home with GrandeurSmart – where innovation, connectivity, and intelligence converge.</p>
                </div>
              </div>
            </div>

            <div class="Products">

              <h2>Our Products <a href="/Products/">See more</a></h2>
              <div className="product-slider">
                <div className="owl-carousel owl-carousel1">
                  <div className="product">
                    <img src="assets/img/Product-20240127T004506Z-001/Product/Grandeur  Prdt hs 10.png" alt=""/>
                    <h3>Grandeur Gateway</h3>
                  </div>

                  <div className="product">
                    <img src="assets/img/Product-20240127T004506Z-001/Product/Grandeur  Prdt hs 11.png" alt=""/>
                    <h3>Grandeur Gateway</h3>
                  </div>

                  <div className="product">
                    <img src="assets/img/Product-20240127T004506Z-001/Product/Grandeur  Prdt hs 12.png" alt=""/>
                    <h3>Grandeur Gateway</h3>
                  </div>

                  <div className="product">
                    <img src="assets/img/Product-20240127T004506Z-001/Product/Grandeur  Prdt hs 3.png" alt=""/>
                    <h3>Grandeur Gateway</h3>
                  </div>

                  <div className="product">
                    <img src="assets/img/Product-20240127T004506Z-001/Product/Grandeur  Prdt hs 4.png" alt=""/>
                    <h3>Grandeur Gateway</h3>
                  </div>

                  <div className="product">
                    <img src="assets/img/Product-20240127T004506Z-001/Product/grdnr Blind prdt.png" alt=""/>
                    <h3>Grandeur Gateway</h3>
                  </div>
                </div>
              </div>
            </div>

  
            <div className="our-partners">
              <h2>Our Partners</h2>
              <div className="owl-carousel owl-carousel3">

                <div className="img" >
                  <img src="assets/img/Alexa.png" alt=""/>
                </div>
                <div className="img" >
                  <img src="assets/img/BLE.png" alt=""/>
                </div>
                <div className="img" >

                  <img src="assets/img/Google_smart_mic.png" alt="" />
                </div>
                <div className="img" >
                  <img src="assets/img/IEEE.png" alt="" />
                </div>
                <div className="img" >
                  <img src="assets/img/Matter.png" alt="" />
                </div>
                <div className="img" >
                  <img src="assets/img/NFC.png" alt="" />
                </div>
                <div className="img" >
                  <img src="assets/img/Zigbee.png" alt=""/>
                </div>
                <div className="img" >
                  <img src="assets/img/ZWave.png" alt="" />
                </div>
              </div>
            </div>

            <div className="partition">
              <div className="part1">
                {/* <div className="logo"> */}
                <div >
                  <img src="assets/img/Grandeur_smart_APP12.png" alt="" srcset="" height={250} width={350} />
                </div>
                <div className="text">
                  <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Deserunt dignissimos modi aut facilis amet alias,
                    est consequuntur repudiandae impedit velit nulla omnis maiores, magni accusantium, asperiores esse? Nesciunt,
                    ab rerum.</p>
                </div>
              </div>



              <div className="part2">
                {/* <div className="logo"> */}
                <div>
                  <img src="assets/img/G_smart_icon_3ii.png" alt="" srcset="" height={250} width={300} />
                </div>
                <div className="text">
                  <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Deserunt dignissimos modi aut facilis amet alias,
                    est consequuntur repudiandae impedit velit nulla omnis maiores, magni accusantium, asperiores esse? Nesciunt,
                    ab rerum.</p>
                </div>
              </div>
            </div>

            <div className="Mobile-app">
              <h2>Our Mobile App</h2>

                <div className="mg-txt">
                  <div className="img">
                    <img src="assets/img/Grandeur_smart-removebg-preview.png" alt="" />
                  </div>
                  <div className="txt">
                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Vero sit, necessitatibus cupiditate ipsam eius
                      neque</p>
                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Minima omnis voluptates sequi possimus architecto
                      rerum nisi totam, id iusto, corporis saepe quos? Deserunt vitae modi unde error at aut minus?</p>
                    <ul>
                      <li>Features</li>
                      <li>Features</li>
                      <li>Features</li>
                    </ul>
                  </div>
                </div>

            </div>

            <canvas id="mobileCanvas"></canvas>

            <div className="blog">
              <h2>Latest On Our Blog</h2>
              <div className="blogs">

                <div className="owl-carousel owl-carousel5">
                  <div className="Blog-card">
                    <div className="img">
                      <img src="assets/img/Banner/dalle-2NCa3VEEE2.png" alt="" />
                    </div>
                    <hr />
                    <div className="text">
                      <p>Admin <span>14/11/2024</span></p>
                      <h2>Lorem ipsum dolor sit amet consectetur adipisicing elit. Optio voluptatibus error non rem
                        inventore
                        itaque minima atque enim eligendi,</h2>

                    </div>
                  </div>


                  <div className="Blog-card">
                    <div className="img">
                      <img src="assets/img/Banner/dalle-2NCa3VEEE2.png" alt="" />
                    </div>
                    <hr />
                    <div className="text">
                      <p>Admin <span>14/11/2024</span></p>
                      <h2>Lorem ipsum dolor sit amet consectetur adipisicing elit. Optio voluptatibus error non rem
                        inventore
                        itaque minima atque enim eligendi,</h2>

                    </div>
                  </div>


                  <div className="Blog-card">
                    <div className="img">
                      <img src="assets/img/Grandeur_smart_Smart_security_safety.png" alt="" />
                    </div>
                    <hr />
                    <div className="text">
                      <p>Admin <span>14/11/2024</span></p>
                      <h2>Lorem ipsum dolor sit amet consectetur adipisicing elit. Optio voluptatibus error non rem
                        inventore
                        itaque minima atque enim eligendi,</h2>

                    </div>
                  </div>
                </div>

              </div>
  </div>



  <hr />

  {/* Start Contact Doctor Area */}
  <div className="contact-doctor-area ptb-100">
    <div className="container">
      <div className="row align-items-center">
        <div className="col-lg-6">
          <div className="contact-doctor-contain" data-aos="fade-right" data-aos-duration="1200" data-aos-delay="200">
            <div className="contact-title">
              <span>Contact Us</span>
              <h2>
                Connect with Grandeur Smart
              </h2>
              <p> 315 Herbert Macaulay Way, Yaba, <br />Lagos 100001, Lagos</p>

            </div>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="contact-doctor-form" data-aos="fade-left" data-aos-duration="1200" data-aos-delay="200">
            <form>
              <div className="row">
                <div className="col-lg-6 col-md-6">
                  <div className="form-group">
                    <input type="text" className="form-control" placeholder="Name*" />
                  </div>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className="form-group">
                    <input type="email" className="form-control" placeholder="Email*" />
                  </div>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className="form-group">
                    <input type="text" className="form-control" placeholder="Phone*" />
                  </div>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className="form-group">
                    <input type="text" className="form-control" placeholder="Subject*" />
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="form-group">
                    <textarea className="form-control" rows="3" placeholder="Message*"></textarea>
                  </div>
                </div>
                <div className="col-lg-12">
                  <button type="submit" className="default-btn active">
                    Send Message
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
  {/* End Contact Doctor Area */}


  <hr />

  {/* Start Footer Area */}
  <div className="footer-area">
    <div className="container">
      <div className="footer pt-100 pb-70">
        <div className="row">
          <div className="col-lg-4 col-sm-6">
            <div className="logo-area" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="200">
              <a href="index.html"><img src="assets/img/logo.png" alt=" " /></a>
              <p>
                Subscribe to Grandeur Smart newsletter for the latest health tips, updates, and exclusive offers. Enter
                your
                email below to join our community and prioritize your well-being."
              </p>
              <form className="newsletter-form" data-toggle="validator">
                <input type="email" className="form-control" placeholder="Your Email" name="EMAIL" required
                  autocomplete="off" />

                <button className="default-btn active" type="submit">
                  Subscribe
                </button>

                <div id="validator-newsletter" className="form-result"></div>
              </form>
            </div>
          </div>

          <div className="col-lg-2 col-sm-6">
            <div className="quick-link" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="400">
              <h3>Quick Link</h3>
              <ul>

                <li>
                  <a href="about-us.html">
                    <i className="ri-add-fill"></i>
                    <p>Blog</p>
                  </a>
                </li>
                <li>
                  <a href="privacy-policy.html">
                    <i className="ri-add-fill"></i>
                    <p>Integration</p>
                  </a>
                </li>
                <li>
                  <a href="terms-conditions.html">
                    <i className="ri-add-fill"></i>
                    <p>Product</p>
                  </a>
                </li>

              </ul>
            </div>
          </div>

          <div className="col-lg-3 col-sm-6">
            <div className="helpful-link" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="600">
              <h3>Address</h3>
              <ul>
                <li>
                  <p>315 Herbert Macaulay Way, Yaba, </p>
                  <p>Lagos 100001, Lagos</p>
                </li>
                <li>
                  <span>Phone:</span><a href="tel:+234-813-997-2473">( +234 813 997 2473)</a>
                </li>
                <li>
                  <span>Email:</span><a href="mailto:info@Grandeursmart.com">info@Grandeursmart.com</a>
                </li>
              </ul>
            </div>
          </div>

          <div className="col-lg-1 col-sm-6">
            <div className="custom-div left" style={{flexDirection: "row", }}>
              <img src="assets/img/google_play.png" alt="" height={50} width={160} />
              <img src="assets/img/apple_store.png" alt="" height={40} width={150} />
            </div>
          </div>
        </div>
      </div>
      <div className="copyright">
        <div className="row">
          <div className="col-lg-6">
            <div className="copy">
              <p>
                © Copywrite 2024 Grandeur Smart
                <a href="yyy" target="_blank">Grandeur Smart</a>
              </p>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
  {/* End Footer Area */}


  </div>

  
    );
}

export default Integration;