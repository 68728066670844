// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import '../ChatBox1.css';

// const ChatBox1 = ({ ticketId }) => {
//     const [messages, setMessages] = useState([]);
//     const [newMessage, setNewMessage] = useState('');

//     useEffect(() => {
//         const fetchMessages = async () => {
//             try {
//                 const response = await axios.get(`http://localhost:8000/api/get_chat_messages/${ticketId}/`);
//                 setMessages(response.data);
//             } catch (error) {
//                 console.error("Error fetching messages", error);
//             }
//         };

//         fetchMessages();
//         const interval = setInterval(fetchMessages, 5000); // Fetch messages every 5 seconds

//         return () => clearInterval(interval); // Cleanup on unmount
//     }, [ticketId]);

//     const handleSendMessage = async () => {
//         if (newMessage.trim()) {
//             try {
//                 await axios.post(`http://localhost:8000/api/add_chat_message/`, {
//                     ticket_id: ticketId,
//                     sender: "User",
//                     message: newMessage,
//                 });
//                 setNewMessage('');
//             } catch (error) {
//                 console.error("Error sending message", error);
//             }
//         }
//     };

//     return (
//         <div className="chatBox">
//             <div className="messages">
//                 {messages.map((msg, index) => (
//                     <div key={index} className={msg.sender === 'admin' ? 'message admin' : 'message user'}>
//                         <strong>{msg.sender}:</strong> {msg.message}
//                     </div>
//                 ))}
//             </div>
//             <div className="messageInput">
//                 <input
//                     type="text"
//                     value={newMessage}
//                     onChange={(e) => setNewMessage(e.target.value)}
//                     placeholder="Type your message"
//                 />
//                 <button onClick={handleSendMessage}>Send</button>
//             </div>
//         </div>
//     );
// };

// export default ChatBox1;


// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import '../ChatBox1.css';

// const ChatBox1 = ({ ticketId }) => {
//     const [messages, setMessages] = useState([]);
//     const [newMessage, setNewMessage] = useState('');

//     useEffect(() => {
//         const fetchMessages = async () => {
//             try {
//                 const response = await axios.get(`http://localhost:8000/api/get_chat_messages/${ticketId}/`);
//                 setMessages(response.data);
//             } catch (error) {
//                 console.error("Error fetching messages", error);
//             }
//         };

//         fetchMessages();
//         const interval = setInterval(fetchMessages, 5000); // Fetch messages every 5 seconds

//         return () => clearInterval(interval); // Cleanup on unmount
//     }, [ticketId]);

//     const handleSendMessage = async () => {
//         if (newMessage.trim()) {
//             try {
//                 await axios.post(`http://localhost:8000/api/add_chat_message/`, {
//                     ticket_id: ticketId,
//                     sender: "User",
//                     message: newMessage,
//                 });
//                 setNewMessage('');
//             } catch (error) {
//                 console.error("Error sending message", error);
//             }
//         }
//     };

//     return (
//         <div className="chatBox">
//             <div className="messages">
//                 {messages.map((msg, index) => (
//                     <div key={index} className={msg.admin ? 'message admin' : 'message user'}>
//                         <div className="message-content">
//                             <strong>{msg.sender}:</strong> {msg.message}
//                         </div>
//                         {msg.admin && <div className="message-time">{msg.time}</div>}
//                     </div>
//                 ))}
//             </div>
//             <div className="messageInput">
//                 <input
//                     type="text"
//                     value={newMessage}
//                     onChange={(e) => setNewMessage(e.target.value)}
//                     placeholder="Type your message"
//                 />
//                 <button onClick={handleSendMessage}>Send</button>
//             </div>
//         </div>
//     );
// };

// export default ChatBox1;


import React, { useState, useEffect } from 'react';
import '../ChatBox1.css';

const ChatBox1 = ({ ticketId }) => {
    const [messages, setMessages] = useState([]);
    const [newMessage, setNewMessage] = useState('');
    const websocket = new WebSocket(`ws://localhost:8000/ws/chat/${ticketId}/`);

    useEffect(() => {
        websocket.onmessage = (event) => {
            const message = JSON.parse(event.data);
            setMessages(prevMessages => [...prevMessages, message]);
        };

        return () => {
            websocket.close();
        };
    }, [ticketId]);

    const handleSendMessage = () => {
        if (newMessage.trim()) {
            websocket.send(JSON.stringify({ message: newMessage }));
            setNewMessage('');
        }
    };

    return (
        <div className="chatBox">
            <div className="messages">
                {messages.map((msg, index) => (
                    <div key={index} className={msg.sender === 'admin' ? 'message admin' : 'message user'}>
                        <div className="message-content">
                            <strong>{msg.sender}:</strong> {msg.message}
                        </div>
                        {msg.admin && <div className="message-time">{msg.time}</div>}
                    </div>
                ))}
            </div>
            <div className="messageInput">
                <textarea
                    rows={4} // Adjust as needed
                    value={newMessage}
                    onChange={(e) => setNewMessage(e.target.value)}
                    placeholder="Type your message"
                />
                <button onClick={handleSendMessage}>Send</button>
            </div>
        </div>
    );
};

export default ChatBox1;

