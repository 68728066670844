import React from 'react';
import { EstatePackageData } from '../components/ProductsData'; 
import EstatePlan from '../components/EstatePlan';
import EstatePlansCarousel from '../components/EstatePlansCarousel';
import '../EstatePlansPage.css'; // Import the CSS file
import FirstNav2 from '../components/FirstNav2.js';
import RelatedProducts from '../components/RelatedProducts';
import ContactDoctor from '../components/ContactDoctor';
import Footer from '../components/Footer';

const EstatePlansPage = () => {
    return (
        <div className="estate-plans-page">
            
            <FirstNav2 />
            
            <EstatePlansCarousel />
            <div className="background-image"></div>
            <h1>Explore Our Estate Plans</h1>
            <div className="estate-plans-list">
                {EstatePackageData.map((estatePlan) => (
                    <EstatePlan key={estatePlan.id} estatePlan={estatePlan} />
                ))}

                <RelatedProducts />
            </div>

            <div style={{backgroundColor: 'white', paddingBottom: '2%',}}>
                <ContactDoctor />
            </div>
            <hr />
            <div style={{backgroundColor: 'white', paddingTop: '2%',}}>
                <Footer />
            </div>

        </div>
    );
};

export default EstatePlansPage;
