import React, { useEffect } from 'react';

const TwitterFeed = () => {
  useEffect(() => {
    const loadTwitterScript = () => {
      if (!document.getElementById('twitter-js')) {
        const script = document.createElement('script');
        script.id = 'twitter-js';
        script.src = 'https://platform.twitter.com/widgets.js';
        document.body.appendChild(script);
      } else {
        window.twttr && window.twttr.widgets.load();
      }
    };
    loadTwitterScript();
  }, []);

  return (
    <div className="twitter-feed">
      <a
        className="twitter-timeline"
        data-height="700"
        href="https://twitter.com/MainLogiXTech?ref_src=twsrc%5Etfw"
      >
        Tweets by yourhandle
      </a>
    </div>
  );
};

export default TwitterFeed;
