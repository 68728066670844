import React, { useState, useEffect, useCallback } from 'react';
import { PaystackButton } from 'react-paystack';
import { useCart } from '../contexts/CartContext';
import { useNavigate } from 'react-router-dom';
import '../CheckoutPage.css';

const CheckoutPage = () => {
    const { cartItems, clearCart } = useCart();
    const navigate = useNavigate();

    const publicKey = process.env.REACT_APP_PAYSTACK_TEST_PUBLIC_KEY;
    const [email, setEmail] = useState("");
    const [name, setName] = useState("");
    const [phone, setPhone] = useState("");
    const [address, setAddress] = useState("");
    const [errors, setErrors] = useState({});
    const [isFormValid, setIsFormValid] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);

    const calculateItemAmount = (item) => {
        if (item.selectedPackage && item[item.selectedPackage]?.amount) {
            return item[item.selectedPackage].amount * item.quantity;
        } else if (!isNaN(item.amount)) {
            return item.amount * item.quantity;
        }
        return 0;
    };

    const ItemAmount = (item) => {
        if (item.selectedPackage && item[item.selectedPackage]?.amount) {
            return item[item.selectedPackage].amount;
        } else if (!isNaN(item.amount)) {
            return item.amount;
        }
        return 0;
    };

    const totalAmount = cartItems.reduce((acc, item) => acc + calculateItemAmount(item), 0);

    const validateForm = useCallback(() => {
        let formErrors = {};
        let isValid = true;

        if (!email) {
            formErrors.email = "Email is required";
            isValid = false;
        } else if (!/\S+@\S+\.\S+/.test(email)) {
            formErrors.email = "Email is invalid";
            isValid = false;
        }

        if (!name) {
            formErrors.name = "Name is required";
            isValid = false;
        }

        if (!phone) {
            formErrors.phone = "Phone number is required";
            isValid = false;
        }

        if (!address) {
            formErrors.address = "Delivery Address is required";
            isValid = false;
        }

        setErrors(formErrors);
        return isValid;
    }, [email, name, phone, address]);

    useEffect(() => {
        if (isSubmitted) {
            setIsFormValid(validateForm());
        }
    }, [email, name, phone, address, validateForm, isSubmitted]);

    const handlePaystackSuccessAction = () => {
        alert("Payment Successful! Your product is on its way!!");
        clearCart(); // Clear cart on successful payment
        navigate('/');
    };

    const handlePaystackCloseAction = () => {
        alert("Are you sure you want to cancel the payment?");
    };

    const handlePayNowClick = () => {
        setIsSubmitted(true);
        if (validateForm()) {
            document.querySelector('.paystack-button').click();
        }
    };

    const componentProps = {
        email,
        amount: totalAmount * 100 * 1782, // Paystack expects amount in kobo
        metadata: {
            name,
            phone,
        },
        publicKey,
        text: "Pay Now",
        onSuccess: handlePaystackSuccessAction,
        onClose: handlePaystackCloseAction,
    };

    return (
        <div className="checkout-container">
            <h2 className="checkout-title">Checkout</h2>

            <div className='checkout-quantity-heading'>
                <p>Name</p>
                <p>Qty</p>
                <p >Sub Total</p>
            </div>
            <ul className="checkout-list">
                {cartItems.map((item) => (
                    
                    <div key={item.id} className="checkout-div">
                        <li className="checkout-item">
                            <div>
                                <p className="checkout-item-name" style={{marginBottom: 0, paddingBottom:0}}>
                                    {item.name} {item.selectedPackage && `(${item.selectedPackage} Package)`} 
                                </p>
        
                                    <p className="checkout-quantity checkout-item-price" style={{marginBottom: 0, paddingBottom:0}} >${(ItemAmount(item)).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>  
        
                            </div>
                            <div>
                                <p>x{item.quantity}</p>
                            </div>
                            <div>
                            <p className="checkout-item-price">${(calculateItemAmount(item)).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
                            </div>
                            
                        </li>
                        
                    </div>
                    
                ))}
            </ul>
            <div className="checkout-total">
                <p>Total Amount: ${totalAmount.toFixed(2).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
            </div>
            {totalAmount > 0 ? (
                <div className="checkout-form">
                    <input
                        type="email"
                        placeholder="Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        className={errors.email && isSubmitted ? 'input-error' : ''}
                    />
                    {errors.email && isSubmitted && <p className="error-text">{errors.email}</p>}
                    <input
                        type="text"
                        placeholder="Name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        className={errors.name && isSubmitted ? 'input-error' : ''}
                    />
                    {errors.name && isSubmitted && <p className="error-text">{errors.name}</p>}
                    <input
                        type="number"
                        placeholder="Phone number"
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                        className={errors.phone && isSubmitted ? 'input-error' : ''}
                    />
                    {errors.phone && isSubmitted && <p className="error-text">{errors.phone}</p>}
                    <input
                        type="text"
                        placeholder="Delivery Address"
                        value={address}
                        onChange={(e) => setAddress(e.target.value)}
                        className={errors.address && isSubmitted ? 'input-error' : ''}
                    />
                    {errors.address && isSubmitted && <p className="error-text">{errors.address}</p>}
                    <button className="checkout-button">
                        Pay Now
                    </button>
                    {/* the button below is to replace the button above once there is a valid key for live payment integration */}

                    {/* <button className="checkout-button" onClick={handlePayNowClick}>
                        Pay Now
                    </button> */}

                    <PaystackButton {...componentProps} className="paystack-button" />
                </div>
            ) : (
                <div className="empty-cart-message">
                    <p>Your cart is empty. Go back and add items to cart.</p>
                    <button className="continue-shopping-button" onClick={() => navigate('/products')}>
                        Continue Shopping
                    </button>
                </div>
            )}
        </div>
    );
};

export default CheckoutPage;
