import { useState } from "react";
import axios from 'axios';
import { useNavigate } from "react-router-dom";

function UserLogin() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('')
    const navigate = useNavigate();

    const handleLogin = async () => {
        try {
            const response = await axios.post('/api/login', 
            {   email, 
                password 
            });

            localStorage.setItem(
                'authToken', 
                response.data.token);

            navigate('/user-dashboard'); //redirect to a user dashboard

        } catch (error) {
            console.error(error);
        }
    };

    return (
        <div>
            <h2>User login</h2>
            <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Email" />
            <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} placeholder="Password" />
            <button onClick={handleLogin}>login</button>
        </div>
    )
}

export default UserLogin;