// import React, { useState} from 'react';
// import '../WelcomePopUp.css';

// const WelcomePopup = ({ onClose }) => {
//   const [name, setName] = useState('');
//   const [email, setEmail] = useState('');
//   const [number, setNumber] = useState('');

//   const handleSubmit = (e) => {
//     e.preventDefault();
//     // Handle form submission logic here
//     console.log('Form submitted:', { name, email, number });
//     onClose();
//   };

//   return (
//     <div className="popup-overlay">
//       <div className="popup-container">
//         <button className="close-button" onClick={onClose}>×</button>
//         <h2>Subscribe to our Newsletter</h2>
//         <form onSubmit={handleSubmit}>
//           <label>
//             Name:
//             <input
//               type="text"
//               value={name}
//               onChange={(e) => setName(e.target.value)}
//               required
//             />
//           </label>
//           <label>
//             Email:
//             <input
//               type="email"
//               value={email}
//               onChange={(e) => setEmail(e.target.value)}
//               required
//             />
//           </label>
//           <label>
//             Phone No:
//             <input
//               type="tel"
//               value={number}
//               onChange={(e) => setNumber(e.target.value)}
//               required
//             />
//           </label>
//           <button type="submit" className="subscribe-button">
//             Subscribe
//           </button>
//         </form>
//       </div>
//     </div>
//   );
// };

// export default WelcomePopup;


import React, { useState, useEffect, useRef } from 'react';
import '../WelcomePopUp.css';

const WelcomePopup = ({ onClose }) => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [number, setNumber] = useState('');
  const popupRef = useRef(null);

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission logic here
    console.log('Form submitted:', { name, email, number });
    onClose();
  };

  const handleClickOutside = (event) => {
    if (popupRef.current && !popupRef.current.contains(event.target)) {
      onClose();
    }
  };

  const handleEscapePress = (event) => {
    if (event.key === 'Escape') {
      onClose();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('keydown', handleEscapePress);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keydown', handleEscapePress);
    };
  }, []);

  return (
    <div className="popup-overlay">
      <div className="popup-container" ref={popupRef}>
        <button className="close-button" onClick={onClose}>×</button>
        <h2>Subscribe to our Newsletter</h2>
        <form onSubmit={handleSubmit}>
          <label>
            Name:
            <input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
          </label>
          <label>
            Email:
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </label>
          <label>
            Phone No:
            <input
              type="tel"
              value={number}
              onChange={(e) => setNumber(e.target.value)}
              required
            />
          </label>
          <button type="submit" className="subscribe-button">
            Subscribe
          </button>
        </form>
      </div>
    </div>
  );
};

export default WelcomePopup;
