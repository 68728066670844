import React, { useState } from "react";
import { toast } from 'react-toastify';
import axios from "axios";
//import { Link } from "react-router-dom";

const ContactDoctor = () => {

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    subject: '',
    message: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
      e.preventDefault();
  
      try {
          const response = await axios.post('http://localhost:8000/api/add_user/', formData);
  
          if (response.status === 200) {
              toast.success("Message sent successfully, we will get in touch with you soon");
              setFormData({ name: '', email: '', phone: '', subject: '', message: '' });
          } else {
              toast.error("Failed to send message. Please try again later.");
          }
      } catch (error) {
          toast.error("An error occurred. Please try again later.");
          console.log(error);
      }
    };

    return(
      <div style={{backgroundColor: 'white',}}>

            {/* Start Contact Doctor Area */}
  <div className="contact-doctor-area ptb-100">
    <div className="container">
      <div className="align-items-cente">
        <div className="col-lg-6">
          <div className="contact-doctor-contain" data-aos="fade-right" data-aos-duration="1200" data-aos-delay="200">
            <div className="contact-title">
              {/* <span className="contact-us">Contact Us</span> */}
              <h2>
                Connect With Us
              </h2>
              
            </div>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="contact-doctor-form" data-aos="fade-left" data-aos-duration="1200" data-aos-delay="200">
            <form onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-lg-6 col-md-6">
                  <div className="form-group">
                    <input 
                      type="text" 
                      className="form-control" 
                      placeholder="Name*"
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                      required />
                  </div>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className="form-group">
                    <input 
                      type="email" 
                      className="form-control" 
                      placeholder="Email*"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      required />
                  </div>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className="form-group">
                    <input 
                    type="text" 
                    className="form-control" 
                    placeholder="Phone*"
                    name="phone"
                    value={formData.phone}
                    onChange={handleChange}
                    required />
                  </div>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className="form-group">
                    <input 
                    type="text" 
                    className="form-control" 
                    placeholder="Subject*"
                    name="subject"
                    value={formData.subject}
                    onChange={handleChange}
                    required />
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="form-group">
                    <textarea 
                    className="form-control" 
                    rows="3" 
                    placeholder="Message*"
                    name="message"
                    value={formData.message}
                    onChange={handleChange}
                    required
                    
                    />
            
                  </div>
                </div>
                <div className="col-lg-12">
                  <button 
                    type="submit" 
                    className="default-btn active">

                      Send Message
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
  {/* End Contact Doctor Area */}

        
        </div>
    )
}

export default ContactDoctor;