// PaymentPage.js (Payment Page)

import React from 'react';
import { useLocation } from 'react-router-dom';

const PaymentPage = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const totalPayment = queryParams.get('total');

  return (
    <div>
      <h2>Payment Page</h2>
      <p>Total Payment: ${totalPayment}</p>
      {/* Payment form and other checkout components go here */}
    </div>
  );
};

export default PaymentPage;
