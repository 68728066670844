import React, { useEffect, useRef, useCallback } from 'react';

const ItemPopup = ({ show, handleClose, item }) => {
  const popupRef = useRef(null);

  const handleClickOutside = useCallback((event) => {
    if (popupRef.current && !popupRef.current.contains(event.target)) {
      handleClose();
    }
  }, [handleClose]);

  useEffect(() => {
    if (show) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [show, handleClickOutside]);

  const popupStyle = {
    display: show ? 'flex' : 'none',
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 1000,
  };

  const innerStyle = {
    position: 'relative',
    padding: '20px',
    width: '80%',
    maxWidth: '600px',
    maxHeight: '80%',
    backgroundColor: 'white',
    borderRadius: '10px',
    boxShadow: '0 5px 15px rgba(0, 0, 0, 0.3)',
    overflowY: 'auto',
  };

  const closeBtnStyle = {
    position: 'absolute',
    top: '10px',
    right: '10px',
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',
    fontFamily: 'Arial',
    color: '#A97D5D',
    fontSize: 'large',
  };

  if (!item) {
    return null;
  }

  return (
    <div style={popupStyle}>
      <div style={innerStyle} ref={popupRef}>
        <button style={closeBtnStyle} onClick={handleClose}>x</button>
        <h2>{item.name}</h2>
        <img src={item.image[0]} alt={item.name} style={{ maxWidth: '100%', height: 'auto' }} />
        <p>{item.description}</p>

        {item.features && (
          <>
            <h3>Features</h3>
            <ul>
              {item.features.map((feature, index) => (
                <li key={index}>{feature}</li>
              ))}
            </ul>
          </>
        )}

        {item.benefits && (
          <>
            <h3>Benefits</h3>
            <ul>
              {item.benefits.map((benefit, index) => (
                <li key={index}>{benefit}</li>
              ))}
            </ul>
          </>
        )}

        {item.applications && (
          <>
            <h3>Applications</h3>
            <ul>
              {item.applications.map((application, index) => (
                <li key={index}>{application}</li>
              ))}
            </ul>
          </>
        )}
      </div>
    </div>
  );
};

export default ItemPopup;
