import React from 'react';
import '../SocialMediaFollow.css';

const SocialMediaFollow = () => {
  return (
    <div className="social-media">
      <h2 style={{ fontSize: 'large' }}>Follow Us</h2>
      <ul>
        <li>
          <a href="https://m.facebook.com/p/Mainlogix-Technology-Limited-100067015779535/" target="_blank" rel="noopener noreferrer">
            <i className="fab fa-facebook-f"></i>
          </a>
        </li>
        <li>
          <a href="https://twitter.com/MainLogiXTech" target="_blank" rel="noopener noreferrer">
            <i className="fab fa-twitter"></i>
          </a>
        </li>
        <li>
          <a href="https://www.instagram.com/mainlogixtechafrica/" target="_blank" rel="noopener noreferrer">
            <i className="fab fa-instagram"></i>
          </a>
        </li>
        <li>
          <a href="https://ng.linkedin.com/company/mainlogix" target="_blank" rel="noopener noreferrer">
            <i className="fab fa-linkedin-in"></i>
          </a>
        </li>
      </ul>
    </div>
  );
};

export default SocialMediaFollow;
