import React, { useEffect, useRef } from 'react';
import Product from '../components/Product';
import ProductsData from '../components/ProductsData';
import FirstNav2 from '../components/FirstNav2';
import ContactDoctor from '../components/ContactDoctor';
import Footer from '../components/Footer';
import RelatedProducts from '../components/RelatedProducts';
import ProductKitCarousel from '../components/ProductKitCarousel';

const Products = () => {
  //////////////////////////////////////////////
///////RUFUS CANVAS 2D ANIMATION CREATION/////
//////////////////////////////////////////////

const canvasRef = useRef(null);

useEffect(() => {
  const canvas = canvasRef.current;
  const ctx = canvas.getContext('2d');
  const width = canvas.width = canvas.offsetWidth;
  const height = canvas.height = canvas.offsetHeight;
  const nodes = [];
  const nodeCount = 50;
  const maxDistance = 50;

  const colors = ['rgba(169, 125, 93, ', 'rgba(92, 117, 94, '];

  class Node {
    constructor(x, y, vx, vy) {
      this.x = x;
      this.y = y;
      this.vx = vx;
      this.vy = vy;
      this.color = this.getRandomColor();
    }

    getRandomColor() {
      const alpha = Math.random() * 0.5 + 0.1; // Random alpha between 0.1 and 0.6
      const colorIndex = Math.floor(Math.random() * colors.length);
      return colors[colorIndex] + alpha + ')';
    }

    update() {
      this.x += this.vx;
      this.y += this.vy;

      if (this.x < 0 || this.x > width) this.vx *= -1;
      if (this.y < 0 || this.y > height) this.vy *= -1;
    }

    draw() {
      ctx.beginPath();
      ctx.arc(this.x, this.y, 3, 0, Math.PI * 2, false);
      ctx.fillStyle = this.color;
      ctx.fill();
    }
  }

  function initNodes() {
    for (let i = 0; i < nodeCount; i++) {
      const x = Math.random() * width;
      const y = Math.random() * height;
      const vx = (Math.random() - 0.5) * 2;
      const vy = (Math.random() - 0.5) * 2;
      nodes.push(new Node(x, y, vx, vy));
    }
  }

  function connectNodes() {
    for (let i = 0; i < nodes.length; i++) {
      for (let j = i + 1; j < nodes.length; j++) {
        const dx = nodes[i].x - nodes[j].x;
        const dy = nodes[i].y - nodes[j].y;
        const distance = Math.sqrt(dx * dx + dy * dy);
        if (distance < maxDistance) {
          ctx.beginPath();
          ctx.moveTo(nodes[i].x, nodes[i].y);
          ctx.lineTo(nodes[j].x, nodes[j].y);
          const lineColor = nodes[i].color;
          ctx.strokeStyle = lineColor;
          ctx.lineWidth = 1.0;
          ctx.stroke();
        }
      }
    }
  }

  function animate() {
    ctx.clearRect(0, 0, width, height);

    nodes.forEach(node => {
      node.update();
      node.draw();
    });

    connectNodes();

    requestAnimationFrame(animate);
  }

  initNodes();
  animate();
}, []);


  return (
    <div>
      <FirstNav2 />
      <ProductKitCarousel />

      <div className="background-image"></div>
            <canvas ref={canvasRef} id="gatewayCanvas" width="1299" height="620" />

        <div className="products-container">
          {ProductsData.map((product) => (
              <Product key={product.id} product={product} /> // Pass product data to Product component
          ))}
          <RelatedProducts />
        </div>
      
      <div style={{backgroundColor: 'white', paddingBottom: '2%',}}>
      <ContactDoctor />
      </div>
      <hr />
      <div style={{backgroundColor: 'white', paddingTop: '2%',}}>
      <Footer />
      </div>
      

    </div>
  );
};

export default Products;
