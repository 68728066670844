import React, { useState } from 'react';
import '../SocialMediaFeeds.css';
import FacebookFeed from './FacebookFeed';
import YouTubeFeed from './YouTubeFeed';
import TwitterFeed from './TwitterFeed';
import LinkedInFeed from './LinkedInFeed';
import InstagramFeed from './InstagramFeed';

const SocialMediaFeeds = () => {
  const [selectedFeed, setSelectedFeed] = useState('youtube');

  const renderFeed = () => {
    switch (selectedFeed) {
      case 'youtube':
        return <YouTubeFeed />;
      case 'facebook':
        return <FacebookFeed />;
      case 'instagram':
        return <InstagramFeed />;
      case 'linkedin':
        return <LinkedInFeed />;
      case 'twitter':
        return <TwitterFeed />;
      default:
        return null;
    }
  };

  return (
    <div className="social-media-feeds">
      <div className="buttons">

      {/* <button
          className={selectedFeed === 'instagram' ? 'active' : ''}
          onClick={() => setSelectedFeed('instagram')}
        >
          Instagram
        </button> */}

        <button
          className={selectedFeed === 'youtube' ? 'active' : ''}
          onClick={() => setSelectedFeed('youtube')}
        >
          YouTube
        </button>
        <button
          className={selectedFeed === 'facebook' ? 'active' : ''}
          onClick={() => setSelectedFeed('facebook')}
        >
          Facebook
        </button>
        
        <button
          className={selectedFeed === 'linkedin' ? 'active' : ''}
          onClick={() => setSelectedFeed('linkedin')}
        >
          LinkedIn
        </button>
        {/* <button
          className={selectedFeed === 'twitter' ? 'active' : ''}
          onClick={() => setSelectedFeed('twitter')}
        >
          Twitter
        </button> */}
      </div>

      <div className="buttons2">

      {/* <button
          className={selectedFeed === 'instagram' ? 'active' : ''}
          onClick={() => setSelectedFeed('instagram')}
        >
          Instagram
        </button> */}

        <button
          className={selectedFeed === 'youtube' ? 'active' : ''}
          onClick={() => setSelectedFeed('youtube')}
        >
          YouTube
        </button>
        {/* <button
          className={selectedFeed === 'facebook' ? 'active' : ''}
          onClick={() => setSelectedFeed('facebook')}
        >
          Facebook
        </button> */}
        
        {/* <button
          className={selectedFeed === 'linkedin' ? 'active' : ''}
          onClick={() => setSelectedFeed('linkedin')}
        >
          LinkedIn
        </button> */}
        {/* <button
          className={selectedFeed === 'twitter' ? 'active' : ''}
          onClick={() => setSelectedFeed('twitter')}
        >
          Twitter
        </button> */}
      </div>

      <div className="feed">{renderFeed()}</div>
    </div>
  );
};

export default SocialMediaFeeds;
