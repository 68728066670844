import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../AdminChatBox1.css';

const AdminChatBox1 = ({ ticketId, ticketName, ticketSubject }) => {
    const [messages, setMessages] = useState([]);
    const [newMessage, setNewMessage] = useState('');

    useEffect(() => {
        const fetchMessages = async () => {
            const response = await axios.get(`http://127.0.0.1:8000/api/get_chat_messages/${ticketId}/`);
            setMessages(response.data);
            console.log(response.data);
        };
        fetchMessages();
    }, [ticketId]);

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
          handleSendMessage();
        }
      };

    

    const handleSendMessage = async () => {
        const response = await axios.post('http://127.0.0.1:8000/api/admin_add_chat_message/', {
            ticket_id: ticketId,
            message: newMessage
        });
        setMessages([...messages, response.data]);
        setNewMessage('');
    };

    return (
        <div className='Admin-chat-container'>
            
            
                <h2>Chat with: {ticketName} </h2>
                <h3>Subject: {ticketSubject}</h3>
                <h4>Ticket ID: {ticketId}</h4>
                <div className='user-chats'> 
                    {messages.map(msg => (
                    
                        <div key={msg._id}>

                            <p>
                                <b>{msg.sender}: </b>
                                {msg.message} 
                            </p>
                                <em>({msg.time})</em>
                        </div>
                    ))}
                </div>
                
               
    
           
            <div className='Admin-chat-input-button'>
                <input
                    
                    type="text"
                    value={newMessage}
                    onChange={(e) => setNewMessage(e.target.value)}
                    placeholder="Type your message"
                    onKeyDown={handleKeyDown}
                />
                <button onClick={handleSendMessage}>Send</button>
            </div>
        </div>
    );
};

export default AdminChatBox1;
