// TawkTo.js
import { useEffect } from 'react';

const TawkTo = () => {
  useEffect(() => {
    const propertyId = process.env.REACT_APP_TAWKTO_PROPERTY_ID;
    const defaultId = process.env.REACT_APP_TAWKTO_DEFAULT;
    
    var Tawk_API=Tawk_API||{}, Tawk_LoadStart=new Date();
    (function(){
    var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
    s1.async=true;
    s1.src=`https://embed.tawk.to/${propertyId}/${defaultId}`;
    s1.charset='UTF-8';
    s1.setAttribute('crossorigin','*');
    s0.parentNode.insertBefore(s1,s0);
    })();
  }, []);

  return null;
};

export default TawkTo;
