import React, { useRef, useState, useEffect }from "react";
import { Link } from "react-router-dom";
import 'bootstrap/dist/js/bootstrap.bundle';

import  OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css'
import 'owl.carousel/dist/assets/owl.theme.default.css';
import '../owl.css';
import FirstNav from "../components/FirstNav";
import GatewayPopup from "../components/GatewayPopup";
import { gatewayContent, mobileAppContent } from "../components/PopupContent";
import ContactDoctor from "../components/ContactDoctor";
import Footer from "../components/Footer";
import SocialMediaFeeds from "../components/SocialMediaFeeds";


function Welcome() {


  const [showPopup, setShowPopup] = useState(false);
  const [popupContent, setPopupContent] = useState(null);

  const handleGatewayReadMoreClick = (content) => (e) => {
    e.preventDefault();
    setPopupContent(content);
    setShowPopup(true);
  };

  const handleCloseGatewayPopup = () => {
    setShowPopup(false);
    setPopupContent(null);
  };

  

//////////////////////////////////////////////
///////RUFUS CANVAS 2D ANIMATION CREATION/////
//////////////////////////////////////////////

const canvasRef = useRef(null);

useEffect(() => {
  const canvas = canvasRef.current;
  const ctx = canvas.getContext('2d');
  const width = canvas.width = canvas.offsetWidth;
  const height = canvas.height = canvas.offsetHeight;
  const nodes = [];
  const nodeCount = 50;
  const maxDistance = 50;

  const colors = ['rgba(169, 125, 93, ', 'rgba(92, 117, 94, '];

  class Node {
    constructor(x, y, vx, vy) {
      this.x = x;
      this.y = y;
      this.vx = vx;
      this.vy = vy;
      this.color = this.getRandomColor();
    }

    getRandomColor() {
      const alpha = Math.random() * 0.5 + 0.1; // Random alpha between 0.1 and 0.6
      const colorIndex = Math.floor(Math.random() * colors.length);
      return colors[colorIndex] + alpha + ')';
    }

    update() {
      this.x += this.vx;
      this.y += this.vy;

      if (this.x < 0 || this.x > width) this.vx *= -1;
      if (this.y < 0 || this.y > height) this.vy *= -1;
    }

    draw() {
      ctx.beginPath();
      ctx.arc(this.x, this.y, 3, 0, Math.PI * 2, false);
      ctx.fillStyle = this.color;
      ctx.fill();
    }
  }

  function initNodes() {
    for (let i = 0; i < nodeCount; i++) {
      const x = Math.random() * width;
      const y = Math.random() * height;
      const vx = (Math.random() - 0.5) * 2;
      const vy = (Math.random() - 0.5) * 2;
      nodes.push(new Node(x, y, vx, vy));
    }
  }

  function connectNodes() {
    for (let i = 0; i < nodes.length; i++) {
      for (let j = i + 1; j < nodes.length; j++) {
        const dx = nodes[i].x - nodes[j].x;
        const dy = nodes[i].y - nodes[j].y;
        const distance = Math.sqrt(dx * dx + dy * dy);
        if (distance < maxDistance) {
          ctx.beginPath();
          ctx.moveTo(nodes[i].x, nodes[i].y);
          ctx.lineTo(nodes[j].x, nodes[j].y);
          const lineColor = nodes[i].color;
          ctx.strokeStyle = lineColor;
          ctx.lineWidth = 1.0;
          ctx.stroke();
        }
      }
    }
  }

  function animate() {
    ctx.clearRect(0, 0, width, height);

    nodes.forEach(node => {
      node.update();
      node.draw();
    });

    connectNodes();

    requestAnimationFrame(animate);
  }

  initNodes();
  animate();
}, []);

  
    return (
        <div>
            

          <FirstNav />
           

          <div>  
           
            <div className='start' >            
            <OwlCarousel items={1}
            autoplay={true}   
              className="owl-theme"  
              loop={true}
              autoplayTimeout={3000}
              dots={true}
              nav={false} 
              navText={["<", ">"]}
              margin={8}>  
                              
                  <div className="start0"  >
                    <div className="slider">
                        <h2>Smart Home Automation</h2>
                        <p>
                          Solutions for controlling and automating home devices via mobile phones.
                          This includes lighting, heating, coolin, and appliance control, enhancing convenience and efficiency.

                        </p>
                    </div>
                  </div>

                  <div  className="start1">
                    <div className="slider">
                        <h2>Smart Security and Safety</h2>
                        <p>Security and Safety: Advanced security systems to protect 
                          life and property, such as smart locks, surveillance cameras, 
                          motion sensors and more, providing peace of mind to homeowners.
                        </p>
                    </div>
                  </div>

                  <div className="start2">
                    <div className="slider">
                        <h2>Smart Energy Management</h2>
                        <p> Tools for efficient energy usage and monitoring, 
                          including smart thermostats, energy-efficient lighting, 
                          and real-time energy consumption tracking, helping reduce 
                          utility bills and environmental impact.
                        </p>
                    </div>
                  </div>

                  <div className="start3">
                    <div className="slider">
                        <h2>Voice Control:</h2>
                        <p>Integration with Google Assistant, Alexa, SMART (by Mainlogix), 
                          and Siri for seamless voice control, allowing users to 
                          manage their devices hands-free.
                        </p>
                    </div>
                  </div>

                  <div  className="start4">
                    <div className="slider">
                        <h2>GrandeurSmart Home Automation</h2>
                        <p>
                        Intelligent systems for monitoring and controlling various devices, 
                        including smart cameras which are capable of distinguishing between humans and animals,
                         automated alerts for notifications and alerts based on sensor data and AI analysis.
                        </p>
                    </div>
                  </div>
              
            </OwlCarousel>  
          </div>  
  
          </div>  



             
          <div className="landing-second-setion">
            <div className="gateway-section">
            <div className="background-image"></div>
            <canvas ref={canvasRef} id="gatewayCanvas" width="1299" height="620" />
        
              <h2>Our Gateway</h2>

              <div className="Image-text">
                <div className="image">
                  <img src="assets/img/grdnr_prdt_1.png" width="250" height="250" alt="" />
                </div>

                <div className="text">
                  <h3>GrandeurSmart Gateway</h3>
                  <p className="gate-paragraph"> GrandeurSmart Gateway is the pinnacle of smart home control, offering an array of features to enhance your
                    living experience:</p>

                  <h3> Key Features</h3>
                  <div>
                    <p className="featureHead">Unified Solution</p>
                    <ul className="bullet1">
                      

                      <li>
                        <span className="featureSubH">
                          Integrated Control: 
                          </span> 
                         The Unified Mobile App 
                        and Gateway Hub allow control of all 
                        third-party devices from different 
                        manufacturers in one place.
                      </li>
                      <li>
                        <span className="featureSubH">
                          Comprehensive Management: 
                        </span>
                         Seamless management of smart home products 
                        enhances user convenience.
                      </li>
                    </ul>
                  </div>

                  <div>
                    <p className="featureHead">Local Control for Privacy</p>
                    <ul className="bullet1">
                      

                      <li>
                        <span className="featureSubH">
                        Edge Device Management: 
                          </span> 
                          Emphasis on local control using edge devices to ensure user privacy.
                      </li>
                      <li>
                        <span className="featureSubH">
                        Data Security: 
                        </span>
                        Keeps data within the user's home network, a feature that sets us apart from many competitors.
                      </li>
                    </ul>
                  </div>

                  <div>
                    <p className="featureHead">Offline Control</p>
                    <ul className="bullet1">
                      

                      <li>
                        <span className="featureSubH">
                        Uninterrupted Functionality: 
                          </span> 
                          Allows users to control their devices even without an internet connection.
                      </li>
                      <li>
                        <span className="featureSubH">
                        Backup Battery: 
                        </span>
                        Ensures continuous operation during power outages.
                      </li>
                    </ul>
                  </div>

                  <div>
                    <p className="featureHead">Real-Time Control</p>
                    <ul className="bullet1">
                      

                      <li>
                        <span className="featureSubH">
                        User-Friendly App: 
                          </span> 
                          Mobile app for remote access and instant notifications.
                      </li>
                      <li>
                        <span className="featureSubH">
                        Automation: 
                        </span>
                        Supports notifications and automation for smarter and more efficient home management.
                      </li>
                      
                    </ul>
                  </div>

                  <div >
                    <Link className="read-more" onClick={handleGatewayReadMoreClick(gatewayContent  )}>Read More...</Link>
                  </div>

               


                    

                  <h3>Plus</h3>

                  <p className="gate-paragraph"> Interoperability: Seamlessly integrate over 100,000 devices from various manufacturers. GrandeurSmart
                    Gateway
                    ensures compatibility, making it a versatile and future-proof solution for your smart home needs.
                    Elevate your home with GrandeurSmart – where innovation, connectivity, and intelligence converge.</p>
                </div>
              </div>
            </div>

            <div className="Products" style={{ backgroundColor: "white", marginBottom: "5%" }}>
              <h2 className="our-products">Our Products <a href="/products" className="more">See more</a></h2>
              <div className="product-slider">
                <OwlCarousel
                  className="owl-theme"
                  loop
                  margin={10}
                  nav={false}
                  dots={false}
                  autoplay
                  autoplayTimeout={3000}
                  autoplayHoverPause
                  responsive={{
                    0: {
                      items: 1
                    },
                    600: {
                      items: 3
                    },
                    1000: {
                      items: 5
                    }
                  }}
                >
                  <div className="product">
                    <img src="assets/img/Product-20240127T004506Z-001/Product/Grandeur  Prdt hs 10.png" alt="" />
                    <h3>Smart Socket</h3>
                  </div>
                  <div className="product">
                    <img src="assets/img/Product-20240127T004506Z-001/Product/Grandeur  Prdt hs 11.png" alt="" />
                    <h3>Smart Adaper</h3>
                  </div>
                  <div className="product">
                    <img src="assets/img/Product-20240127T004506Z-001/Product/Grandeur  Prdt hs 12.png" alt="" />
                    <h3>Smart Doorbell</h3>
                  </div>
                  <div className="product">
                    <img src="assets/img/Product-20240127T004506Z-001/Product/Grandeur  Prdt hs 3.png" alt="" />
                    <h3>Smart Lock</h3>
                  </div>
                  <div className="product">
                    <img src="assets/img/Product-20240127T004506Z-001/Product/Grandeur  Prdt hs 4.png" alt="" />
                    <h3>Motion Sensor</h3>
                  </div>
                  <div className="product">
                    <img src="assets/img/Product-20240127T004506Z-001/Product/grdnr Blind prdt.png" alt="" />
                    <h3>Smart Motor</h3>
                  </div>
                </OwlCarousel>
              </div>
            </div>

            <div className="Mobile-app">

            
              <h2 >Our Mobile App</h2>

                <div className="mg-txt">
                  <div className="img">
                    <img src="assets/img/AppImage/Grandeur_smart_wedban2.jpg" alt="" />
                  </div>
                  <div className="txt">
                    <p>Our app supports advanced notifications and automation, 
                      making homes and cities smarter and more efficient. 
                    </p>

                    <p>By seamlessly integrating devices from various manufacturers, 
                      GrandeurSmart enhances the overall smart experience, 
                      enabling users to create customized automation scenarios 
                      and receive real-time alerts.
                    </p>
                    <div className="text">

                    <h3 >Features</h3>
                    </div>
                    <ul className="bullet2">
                    
                  
                    <li>
                        <span className="featureSubH">
                        Unified Control: 
                        </span>
                        Supports notifications and automation for smarter and more efficient home management.
                    </li>

                    <div>
                    <Link className="read-more" onClick={handleGatewayReadMoreClick(mobileAppContent)}>Read More...</Link>
                    </div>
                   
                    
                    </ul>
                  </div>
                </div>

                
               
            </div>
            
            <GatewayPopup show={showPopup} handleClose={handleCloseGatewayPopup} content={popupContent} />
            <div style={{backgroundColor: "rgba(255, 255, 255, 0.7)", marginBottom: "0%",width: "100%"}}>
              <div className="partition">

             
                <div className="part1" >

                  <div className="logo">
                    <img src="assets/img/app_narrationj.jpg" alt=" " />
                  </div>

                  <div className="logo">
                    <img src="assets/img/G_smart_icon_3ii.png" alt=" "  />
                  </div>
                
                </div>
              

                

                <div className="part2">

                  <div className="tt">
                    <p>
                    By seamlessly integrating devices from various manufacturers, 
                    GrandeurSmart enhances the overall smart experience, enabling 
                    users to create customized automation scenarios and receive 
                    real-time alerts.
                    </p>
                  </div>
                  
                  <div className="tt">
                    <p>
                    GrandeurSmart features an enhanced AI voice assistant called SMART. This personalized 
                    voice control system allows users to interact with their home 
                    and city devices effortlessly, including playing music, 
                    controlling lights, and differentiating between humans and 
                    animals via smart cameras e.g ‘Hey SMART’ Turn on the light 
                    or Play me some Music, or for Customized name ‘Hey Joy’ 
                    Turn on the light or Play me some Music.
                    </p>
                  </div>
                </div>
              </div>

              {/* mobile view partition */}

              <div className="partition1">
              <div className="part1">
                <div className="logo">
                  <img src="assets/img/app_narrationj.jpg" alt=" " />
                </div>
                <div className="text">
                  <p>
                  By seamlessly integrating devices from various manufacturers, 
                  GrandeurSmart enhances the overall smart experience, enabling 
                  users to create customized automation scenarios and receive 
                  real-time alerts.
                  </p>
                </div>
              </div>



              <div className="part2">
                <div className="logo">
                  <img src="assets/img/G_smart_icon_3ii.png" alt=" " />
                </div>
                <div className="text">
                  <p>
                  GrandeurSmart features an enhanced AI voice assistant called SMART. This personalized 
                  voice control system allows users to interact with their home 
                  and city devices effortlessly, including playing music, 
                  controlling lights, and differentiating between humans and 
                  animals via smart cameras e.g ‘Hey SMART’ Turn on the light 
                  or Play me some Music, or for Customized name ‘Hey Joy’ 
                  Turn on the light or Play me some Music .
                  </p>
                </div>
              </div>
            </div>
            





            </div>

            


            


            {/* <canvas id="mobileCanvas"></canvas> */}

          <div style={{backgroundColor: "white", marginTop: "0%"}}>

            <div className="our-partners">
              <h2>Our Partners</h2>
              <OwlCarousel
                className="owl-theme"
                loop
                margin={8}
                nav={false}
                dot={false}
                autoplay
                autoplayTimeout={3000}
                autoplayHoverPause
                responsive={{
                  0: {
                    items: 2
                  },
                  600: {
                    items: 3
                  },
                  1000: {
                    items: 5
                  }
                }}
              >
                <div className="img">
                  <img src="assets/img/Alexa.png" alt="" />
                </div>
                <div className="img">
                  <img src="assets/img/BLE.png" alt="" />
                </div>
                <div className="img">
                  <img src="assets/img/Google_smart_mic.png" alt="" />
                </div>
                <div className="img">
                  <img src="assets/img/IEEE.png" alt="" />
                </div>
                <div className="img">
                  <img src="assets/img/Matter.png" alt="" />
                </div>
                <div className="img">
                  <img src="assets/img/NFC.png" alt="" />
                </div>
                <div className="img">
                  <img src="assets/img/Zigbee.png" alt="" />
                </div>
                <div className="img">
                  <img src="assets/img/ZWave.png" alt="" />
                </div>
              </OwlCarousel>
          </div>

            
            <div className="blog">
              <h2>Latest On Our Blog</h2>
              <div className="blogs">
                <OwlCarousel
                  className="owl-theme"
                  loop
                  margin={10}
                  nav
                  autoplay
                  autoplayTimeout={3000}
                  autoplayHoverPause
                  responsive={{
                    0: {
                      items: 1
                    },
                    600: {
                      items: 2
                    },
                    1000: {
                      items: 3
                    }
                  }}
                >
                  <div className="Blog-card">
                    <div className="img">
                      <img src="assets/img/Grandeur_smart_Smart_security_safety.png" alt="" />
                    </div>
                    <hr />
                    <div className="text">
                      <p className="gate-paragraph">Admin <span>14/03/2024</span></p>
                      <h2>
                        We are revolutionizing the way you interact with your home.
                        Our advanced smart home solutions, powered by AI and IoT,
                        provide unparalleled convenience, security, and efficiency.
                        Discover the next generation of living with Grandeursmart.
                      </h2>
                    </div>
                  </div>

                  <div className="Blog-card">
                    <div className="img">
                      <img src="assets/img/Banner/dalle-2NCa3VEEE2.png" alt="" />
                    </div>
                    <hr />
                    <div className="text">
                      <p className="gate-paragraph">Admin <span>25/04/2024</span></p>
                      <h2>
                        At its core, GrandeurSmart gateway isn't just a gateway –
                        it's a catalyst for unparalleled connectivity,
                        seamlessly bridging the divide between devices,
                        networks, and data. Designed with precision engineering
                        and powered by cutting-edge technology, it serves as the
                        central nervous system of your IoT infrastructure,
                        orchestrating a symphony of data exchange with unparalleled
                        efficiency and reliability.
                      </h2>
                    </div>
                  </div>

                  <div className="Blog-card">
                    <div className="img">
                      <img src="assets/img/Banner/Grandeur_smart_banner_1a.jpg" alt="" />
                    </div>
                    <hr />
                    <div className="text">
                      <p className="gate-paragraph">Admin <span>19/03/2024</span></p>
                      <h2>
                        Imagine a home security system that doesn't just detect intruders,
                        but anticipates their every move adjusting lighting,
                        activating alarms, alerting authorities and more with split-second
                        precision. Picture an industrial facility where predictive
                        maintenance isn't just a luxury, but a cornerstone of
                        operational efficiency preventing costly downtime and
                        ensuring the safety of workers at every turn.
                      </h2>
                    </div>
                  </div>
                </OwlCarousel>
              </div>
            </div>

            <hr />

            <SocialMediaFeeds />



            <hr />
            <ContactDoctor />

            <hr />

            <Footer />

          </div>
          </div>
        </div>

  
    );
}

export default Welcome;