// import React, { useEffect } from 'react';

// const YouTubeFeed = () => {
//   useEffect(() => {
//     const loadYouTubeScript = () => {
//       if (!document.getElementById('youtube-js')) {
//         const script = document.createElement('script');
//         script.id = 'youtube-js';
//         script.src = 'https://apis.google.com/js/platform.js';
//         document.body.appendChild(script);
//       } else {
//         window.gapi && window.gapi.ytsubscribe && window.gapi.ytsubscribe.go();
//       }
//     };
//     loadYouTubeScript();
//   }, []);

//   return (
//     <div className="youtube-feed">
//       <div
//         className="g-ytsubscribe"
//         data-channelid="UCgqF72YjHD6JcNA6uBUocew"
//         data-layout="full"
//         data-theme="default"
//         data-count="default"
//       ></div>
//     </div>
//   );
// };

// export default YouTubeFeed;



import React, { useEffect, useState } from 'react';
import '../YouTubeFeed.css';

const YouTubeFeed = () => {
  const [videos, setVideos] = useState([]);

  useEffect(() => {
    const fetchVideos = async () => {
      const response = await fetch(
        `https://www.googleapis.com/youtube/v3/search?key=AIzaSyDxKfgvkuEVsIwiMOa7oYUApZxILdyyWpU&channelId=UCgqF72YjHD6JcNA6uBUocew&part=snippet,id&order=date&maxResults=8`
      );
      const data = await response.json();
      setVideos(data.items);
    };

    fetchVideos();
  }, []);

  return (
    <div className="youtube-feed">
      <div className="video-grid">
        {videos.map((video) => (
          <div key={video.id.videoId} className="video">
            <iframe
              src={`https://www.youtube.com/embed/${video.id.videoId}`}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              title={video.snippet.title}
            ></iframe>
            <p>{video.snippet.title}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default YouTubeFeed;
