import { useEffect } from 'react';
import axios from 'axios';

const GeolocationRedirect = () => {
  // useEffect(() => {
  //   const fetchGeolocationData = async () => {
  //     try {
  //       const response = await axios.get('https://api.ipgeolocation.io/ipgeo', {
  //         params: {
  //           apiKey: process.env.REACT_APP_IPGEOLOCATION_API_KEY,
  //         },
  //       });
  //       const { country_name } = response.data;
  //       console.log("I am response data", country_name);
        
  //       //uncomment the below code for redirection to kickstart

  //       switch (country_name) {
  //         case 'Nigeria':
  //           window.location.replace('https://www.skillzity.com');
  //           break;
  //         case 'Egypt':
  //           window.location.replace('https://www.grandeursmart.skillzity.com');
  //           break;
  //         // Add other countries as needed
  //         default:
  //           // Default case if country_name doesn't match any case
  //           console.log('Country not matched:', country_name);
  //       }
  //     } catch (error) {
  //       console.error('Error fetching geolocation data:', error);
  //     }
  //   };

  //   fetchGeolocationData();
  // }, []);

  return null; 
};

export default GeolocationRedirect;
