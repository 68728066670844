import React, { useState } from 'react';
import axios from 'axios';
import '../ContactChatBox.css';

const ContactChatBox = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [message, setMessage] = useState('');
    const [ticketId, setTicketId] = useState(null);
    const [messages, setMessages] = useState([]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post('http://localhost:8000/api/add_user/', { name, email, phone, message });
            setTicketId(response.data.ticket._id);
            setMessages([response.data.initial_message]);
        } catch (error) {
            console.error("There was an error submitting the form!", error);
        }
    };

    const handleSendMessage = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post('http://localhost:8000/api/add_chat_message/', { ticket_id: ticketId, sender: name, message });
            setMessages([...messages, response.data]);
            setMessage('');
        } catch (error) {
            console.error("There was an error sending the message!", error);
        }
    };

    return (
        <div className="contactChatBox">
            {ticketId ? (
                <div className="chatBox">
                    <div className="chatHeader">
                        <h2>Chat with Us</h2>
                    </div>
                    <div className="chatMessages">
                        {messages.map((msg) => (
                            <div key={msg._id} className={`message ${msg.sender === 'user' ? 'userMessage' : 'adminMessage'}`}>
                                {msg.message}
                            </div>
                        ))}
                    </div>
                    <div className="chatInput">
                        <form onSubmit={handleSendMessage}>
                            <input
                                type="text"
                                value={message}
                                onChange={(e) => setMessage(e.target.value)}
                                placeholder="Type your message here..."
                                required
                            />
                            <button type="submit">Send</button>
                        </form>
                    </div>
                </div>
            ) : (
                <div>
                    <div className="chatboxHeader">
                        <h2 className='chatbox-contact'>Contact Us</h2>
                        <p>Fill out the form below to start a chat with us.</p>
                    </div>
                    <div className="chatboxBody">
                        <form onSubmit={handleSubmit} className="form">
                            <label className="inputLabel">
                                Name
                                <input
                                    type="text"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                    className="inputField"
                                    required
                                />
                            </label>
                            <label className="inputLabel">
                                Email
                                <input
                                    type="email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    className="inputField"
                                    required
                                />
                            </label>
                            <label className="inputLabel">
                                Phone Number
                                <input
                                    type="tel"
                                    value={phone}
                                    onChange={(e) => setPhone(e.target.value)}
                                    className="inputField"
                                    required
                                />
                            </label>
                            <label className='inputLabel'>
                                Short Message
                                <textarea
                                    placeholder="Message"
                                    value={message}
                                    onChange={(e) => setMessage(e.target.value)}
                                    className='inputField'
                                    required
                                />
                            </label>
                            <button type="submit" className="startChatBtn">
                                Start Chat
                            </button>
                        </form>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ContactChatBox;
